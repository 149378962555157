import { Tab } from '@starsoft/common/src/components/Tabs/Tab/props';

export const tabsOptions: Tab<string>[] = [
  {
    label: 'tab_casino',
    icon: 'fa-solid fa-spade fa-swap-opacity',
    value: '/casino',
  },
  {
    label: 'tab_sportsbook',
    icon: 'fa-solid fa-basketball',
    value: '/sports/live',
  },
];
