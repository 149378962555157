import { IconButton } from '@starsoft/common/components';
import { useNotificationGroupsMe } from '@/api/notification-groups';
import { ErrorChip } from '@starsoft/common/components';
import styles from './styles.module.scss';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { ModalsKey } from '@/enums/modalsKey';

export default function NotificationButton() {
  const { isPending, unreadCount, error, refetch } = useNotificationGroupsMe();
  const { navigateToModal } = useModalNavigation();

  function onOpen() {
    navigateToModal(ModalsKey.Notifications);
  }
  if (error) {
    return <ErrorChip action={refetch} />;
  }

  return (
    <IconButton onClick={onOpen} isLoading={isPending}>
      <i className="fad fa-envelope fa-swap-opacity" />

      {unreadCount > 0 && (
        <div className={styles.indicator}>
          {unreadCount > 99 ? '99+' : unreadCount}
        </div>
      )}
    </IconButton>
  );
}
