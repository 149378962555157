import baseAPI from '@/api';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { UserBonus } from '@/models/userBonus';
import { QueryFunctionContext } from '@tanstack/react-query';
import { UseUserBonusQueryKeyProps } from './props';
import { UserBonusInputDto } from '@/services/dto/user-bonus/user-bonus-input.dto';

export async function fetchUserBonus({
  queryKey,
  pageParam,
}: QueryFunctionContext<UseUserBonusQueryKeyProps>) {
  const { limit, statusIds }: UserBonusInputDto = queryKey[1];
  const response = await baseAPI.get<PaginatedResponse<UserBonus>>(
    '/user-bonus',
    {
      params: {
        limit,
        page: pageParam,
        statusIds: statusIds?.join(','),
      },
    },
  );
  return response.data;
}
