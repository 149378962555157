import { useIsMobile } from '@starsoft/common/hooks';
import { platformAssets } from '@/utils/assets';
import { logoSideBarVariants, logoSideBarVariantsMobile } from '../../variants';
import Link from '@/components/core/Link';
import { m } from 'framer-motion';
import { useSelector } from 'react-redux';
import { RootState } from '@/lib/store';

import styles from '../../styles.module.scss';
import useLayoutSettings from '@/hooks/theme/useLayoutSettings';

export default function SidebarHeaderContentLogo() {
  const mobileSize = useIsMobile();
  const collapsedSidebar: boolean = useSelector(
    (state: RootState) => state.sideBar.collapsed,
  );
  const { isV2LayoutVariant } = useLayoutSettings();

  return (
    <Link
      href="/"
      className={`${styles.container__link} ${collapsedSidebar ? styles['container__link--collapsed'] : ''}`}
    >
      {!isV2LayoutVariant && (
        <m.img
          className={`${styles['container__mobile-logo']} ${collapsedSidebar ? styles['container__mobile-logo--collapsed'] : ''}`}
          initial="open"
          animate={(mobileSize ? false : !collapsedSidebar) ? 'open' : 'closed'}
          variants={logoSideBarVariantsMobile}
          src={platformAssets.logo.icon}
          alt="logo"
        />
      )}
      <m.img
        className={`${styles['container__logo']} ${collapsedSidebar ? styles['container__logo--collapsed'] : ''}`}
        initial="open"
        alt="web-logo"
        animate={
          (isV2LayoutVariant ? true : mobileSize ? false : !collapsedSidebar)
            ? 'open'
            : 'closed'
        }
        variants={logoSideBarVariants}
        src={platformAssets.logo.web.dark}
      />
    </Link>
  );
}
