import useTranslation from 'next-translate/useTranslation';
import SidebarItemSection from '../Item/Section';
import { useRouter } from 'next/router';

export default function TournamentSection() {
  const { t } = useTranslation('common');
  const { push } = useRouter();

  function handleNavigate() {
    push(`/tournaments`);
  }

  return (
    <SidebarItemSection
      label={t('tournaments_label')}
      icon="fa-solid fa-trophy"
      onClick={handleNavigate}
    />
  );
}
