import baseAPI from '@/api';
import { UserBonus } from '@/models/userBonus';
import { ClaimUserBonusCheckpointInputDto } from '@/services/dto/user-bonus/claim-user-bonus-checkpoint-input.dto';

export async function fetchUserBonusClaimCheckpoint(
  data: ClaimUserBonusCheckpointInputDto,
) {
  const response = await baseAPI.patch<UserBonus>(
    `/user-bonus/claim-checkpoints`,
    data,
  );
  return response.data;
}
