import { Transition, Variants } from 'framer-motion';

const transition: Transition = {
  duration: 0.25,
  ease: 'linear',
  delay: 0.5,
};

export const fadeInLeft: Variants = {
  hidden: {
    opacity: 0,
    x: -20,
    transition,
  },
  show: {
    opacity: 1,
    x: 0,
    transition,
  },
};
