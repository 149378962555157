import {
  NotificationGroupId,
  NotificationTypeId,
} from '@starsoft/common/enums';
import {
  AlertExtraParams,
  AlertType,
  Notification,
} from '@starsoft/common/models';
import {
  addNotificationToasty,
  removeNotificationToasty,
} from '@starsoft/common/store';
import { generateRandomId } from '@starsoft/common/utils';
import { Store } from 'redux';

export const NOTIFICATION_DURATION: number = 10000;

export class NotificationsService<T> {
  private store: Store;

  constructor(store: Store) {
    this.store = store;
  }

  addNotification(notification: Notification<T>) {
    this.store.dispatch(addNotificationToasty(notification));

    setTimeout(() => {
      this.store.dispatch(removeNotificationToasty(notification.id));
    }, NOTIFICATION_DURATION);
  }

  remove(id: number): void {
    this.store.dispatch(removeNotificationToasty(id));
  }

  addAlert(alertExtraParams: AlertExtraParams) {
    const id: number = generateRandomId();

    const notification: Notification<T> = {
      id,
      pinned: false,
      type: {
        id: NotificationTypeId.Alert,
        group: {
          id: NotificationGroupId.Inbox,
          name: 'Inbox',
        },
        name: 'Alert',
      },
      extra: alertExtraParams,
      createdAt: `${new Date()}`,
    };

    this.addNotification(notification);
  }

  info({ title, message, params }: Omit<AlertExtraParams, 'type'>) {
    this.addAlert({
      title,
      message,
      params,
      type: AlertType.Info,
    });
  }

  error({ title, message, params }: Omit<AlertExtraParams, 'type'>) {
    this.addAlert({
      title,
      message,
      params,
      type: AlertType.Error,
    });
  }

  success({ title = '', message, params }: Omit<AlertExtraParams, 'type'>) {
    this.addAlert({
      title,
      message,
      params,
      type: AlertType.Success,
    });
  }
}
