import SidebarCollapsePage from './Page';
import { SidebarCollapsePagesProps } from './props';
import styles from './styles.module.scss';
import { useMemo } from 'react';

export default function SidebarCollapsePages({
  pages,
  notCollapse,
}: SidebarCollapsePagesProps) {
  const Pages: JSX.Element[] = useMemo(
    () =>
      pages.map(page => (
        <SidebarCollapsePage
          page={page}
          key={`sidebar_not_collapsed_item_page_${page.label}`}
          notCollapse={notCollapse}
        />
      )),
    [pages, notCollapse],
  );
  return <div className={styles.container}>{Pages}</div>;
}
