import { useQuery } from '@tanstack/react-query';
import { UserRewardsQueryKeys } from '../../queryKeys';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { fetchUserRewards } from './service';
import { Reward } from '@/models/userReward/reward';
import { useUserAccount } from '@/api/user/queries';

export function useUserRewards() {
  const { isAuthorized } = useUserAccount();
  const queryKey = [UserRewardsQueryKeys.Primary];

  const { data, refetch, isPending, error, fetchStatus } = useQuery<
    PaginatedResponse<Reward>,
    AxiosError<GenericError>
  >({
    queryKey,
    queryFn: fetchUserRewards,
    enabled: isAuthorized,
    staleTime: 5 * 1000,
    refetchInterval: 60 * 1000 * 10, //10 minutes
  });

  return {
    error,
    refetch,
    isLoading: isPending && fetchStatus !== 'idle',
    rewards: data,
  };
}
