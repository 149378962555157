import useTranslation from 'next-translate/useTranslation';
import {
  useCountdown,
  useIsMobile,
  useLottiePlayer,
  usePathname,
} from '@starsoft/common/hooks';
import styles from './styles.module.scss';
import useLayoutSettings from '@/hooks/theme/useLayoutSettings';
import { useDetectDepositBoosts } from '@/hooks/useDetectDepositBoosts';
import { ErrorChip } from '@starsoft/common/components';
import Tab from '@/components/Tab';
import { m as motion } from 'framer-motion';
import { queryClient } from '@/lib/providers/TanstackQueryProvider';
import { UserBonusQueryKeys } from '@/api/user-bonus/queryKeys';
import { fadeInRight } from './variants/fadeInRight';
import { fadeInLeft } from './variants/fadeInLeft';
import { deleteCookie, getCookie, hasCookie, setCookie } from 'cookies-next';
import { CookiesKey } from '@/enums/cookiesKey';
import { Either } from '@starsoft/common/interfaces';
import { UserBonus } from '@/models/userBonus';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function BonusChip() {
  const { t } = useTranslation('common');
  const { isV2LayoutVariant } = useLayoutSettings();
  const { isLoading, error, bonus, refetch } = useDetectDepositBoosts();
  const { push } = useRouter();
  const pathname: string = usePathname();
  const isAllowedPage: boolean =
    pathname === '/' || ['/casino', '/bonus'].includes(pathname);
  const isMobile: boolean = useIsMobile();
  const firstBonus: Either<UserBonus, undefined> = bonus?.[0];
  const hideBonusCardMobile: boolean = hasCookie(
    CookiesKey.HideBonusCardMobile,
  );
  const lastUserBonusId: string = getCookie(CookiesKey.LastUserBonusId);

  const { formattedTimeLeft } = useCountdown({
    targetDate: firstBonus?.expiresAt,
    disabled: isLoading || !bonus || bonus?.length == 0,
    onCountdownComplete: () => {
      queryClient.invalidateQueries({ queryKey: [UserBonusQueryKeys.Primary] });
    },
  });
  const { lottieAnimationRef } = useLottiePlayer({
    path: '/gift-box.json',
    dependecyArray: [
      bonus,
      isMobile,
      isLoading,
      isAllowedPage,
      hideBonusCardMobile,
    ],
  });

  function onClose() {
    setCookie(CookiesKey.LastUserBonusId, firstBonus?.id);
    setCookie(CookiesKey.HideBonusCardMobile, 1);
  }

  function onClick() {
    if (isMobile) {
      onClose();
    }

    push('/bonus#bonus-card-claimable');
  }

  function handleNewBonus() {
    if (
      !firstBonus ||
      !lastUserBonusId ||
      +lastUserBonusId === firstBonus?.id
    ) {
      return;
    }

    deleteCookie(CookiesKey.HideBonusCardMobile);
  }

  useEffect(handleNewBonus, [firstBonus, lastUserBonusId]);

  if (error) {
    return <ErrorChip action={refetch} />;
  }

  if (isMobile && (!isAllowedPage || hideBonusCardMobile)) {
    return (
      <div
        style={{
          display: 'none',
        }}
      />
    );
  }

  if (isLoading || bonus?.length == 0 || typeof window == 'undefined') {
    return (
      <>
        {isV2LayoutVariant ? (
          <div
            style={{
              display: 'none',
            }}
          />
        ) : (
          <div className="desktop-only">
            <Tab />
          </div>
        )}
      </>
    );
  }

  return (
    <motion.div
      className={styles.container}
      variants={isMobile ? fadeInRight : fadeInLeft}
      initial="hidden"
      animate="show"
      exit="hidden"
    >
      <div onClick={onClick} className={styles.link}>
        <div className={styles.link__lottie__placeholder} />
        <div className={styles.link__lottie} ref={lottieAnimationRef} />
        <div className={styles.link__wrapper}>
          <div className={styles.link__wrapper__description}>
            {t('available_bonus')}{' '}
          </div>
          <div className={styles.link__wrapper__title}>{formattedTimeLeft}</div>
        </div>
        <div className={styles.link__icon}>
          <i className="fa-solid fa-bolt" />
        </div>
      </div>

      <button className={styles.link__close} onClick={onClose}>
        <i className="fa-solid fa-x" />
      </button>
    </motion.div>
  );
}
