import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { UserBonusQueryKeys } from '../../queryKeys';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { fetchUserBonus } from './service';
import { UseUserBonusProps, UseUserBonusQueryKeyProps } from './props';
import { useMemo } from 'react';
import {
  useInfiniteQueryCallbacks,
  useInfiniteQueryReset,
} from '@starsoft/common/hooks';
import { useUserAccount } from '@/api/user/queries';
import { PaginatedResponse } from '@starsoft/common/models';
import { UserBonus } from '@/models/userBonus';
import { UserBonusCheckpointStatus } from '@/enums/userBonusCheckpointStatus';
import { UserBonusInputDto } from '@/services/dto/user-bonus/user-bonus-input.dto';

export function useUserBonus(props?: UseUserBonusProps) {
  const { statusIds, enabled = true } = props ?? {};
  const { isAuthorized } = useUserAccount();

  const filter: UserBonusInputDto = {
    limit: 6,
    statusIds,
  };
  const queryKey: UseUserBonusQueryKeyProps = [
    UserBonusQueryKeys.Primary,
    filter,
  ];

  const { getNextPageParam, getPreviousPageParam } =
    useInfiniteQueryCallbacks();

  useInfiniteQueryReset(queryKey);

  const { data, refetch, isPending, error, fetchStatus, ...infinityQuery } =
    useInfiniteQuery<
      PaginatedResponse<UserBonus>,
      AxiosError<GenericError>,
      InfiniteData<PaginatedResponse<UserBonus>>,
      UseUserBonusQueryKeyProps
    >({
      queryKey,
      queryFn: fetchUserBonus,
      getNextPageParam,
      getPreviousPageParam,
      initialPageParam: 1,
      enabled: isAuthorized && enabled,
      staleTime: 5 * 1000,
    });

  const flatedData: UserBonus[] = useMemo(
    () => data?.pages?.flatMap(page => page?.data),
    [data],
  );

  const curretCheckpointIndex: number = useMemo(
    () =>
      flatedData
        ?.flatMap(item => item?.checkpoints)
        ?.findIndex(
          checkpoint => checkpoint?.status == UserBonusCheckpointStatus.Created,
        ),
    [flatedData],
  );

  return {
    ...infinityQuery,
    error: error as AxiosError<GenericError>,
    refetch,
    isLoading:
      isPending && fetchStatus !== 'idle' && typeof window != 'undefined',
    isPending:
      isPending && fetchStatus !== 'idle' && typeof window != 'undefined',
    bonus: flatedData ?? [],
    metadata: data?.pages?.[0]?.metadata,
    curretCheckpointIndex,
  };
}
