import baseAPI from '@/api';
import { UserBonus } from '@/models/userBonus';
import { UpdateUserBonusInputDto } from '@/services/dto/user-bonus/update-user-bonus-input-dto';

export async function fetchToggleUserBonusActive({
  id,
  active,
}: UpdateUserBonusInputDto) {
  const response = await baseAPI.patch<UserBonus>(`/user-bonus/${id}`, {
    active,
  });
  return response.data;
}
