import { SidebarCollapsePageProps } from './props';
import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';
import Link from '@/components/core/Link';
import { Image, ToolTip } from '@starsoft/common/components';
import { useIsPageActive } from '@/hooks/useIsPageActive';
import useTransactionsNavigation from '@/hooks/useTransacionsNavigation';
import { ModalsKey } from '@/enums/modalsKey';
import useLayoutSettings from '@/hooks/theme/useLayoutSettings';
import { SettingLayoutComponentId } from '@starsoft/common/models';

export default function SidebarCollapsePage({
  page,
  notCollapse,
  shouldNotHighlight,
}: SidebarCollapsePageProps) {
  const { t } = useTranslation('common');
  const isActive: boolean = useIsPageActive(page);
  const { getModalSearchParams } = useTransactionsNavigation();
  const { isCollapsed, layoutVariant } = useLayoutSettings();

  if (page?.hidden) {
    return null;
  }

  return (
    <ToolTip
      visible={isCollapsed && !notCollapse}
      titleComponent={t(page.label, {
        provider: process.env.NEXT_PUBLIC_BET_NAME,
        app_provider: process.env.NEXT_PUBLIC_BET_NAME,
      })}
    >
      <div>
        <Link
          className={`${styles.container} ${isActive && !shouldNotHighlight ? styles['container--current'] : ''} ${isCollapsed && !notCollapse ? styles['container--collapsed'] : ''} ${layoutVariant === SettingLayoutComponentId.LayoutV3 ? styles['container--v3'] : ''}`}
          privateRoute={page.isProtected}
          href={
            [ModalsKey.Deposit, ModalsKey.Withdraw].includes(page?.modal)
              ? getModalSearchParams()
              : page.paths?.[0]
          }
          modal={page.modal}
          onClick={() => page?.clickHandler?.()}
        >
          {page?.isImage ? (
            <Image
              className={`${styles.container__icon} ${styles['container__icon']}`}
              width={20}
              height={20}
              src={page.icon}
              alt={t(page.label)}
            />
          ) : (
            <i className={`${page.icon} ${styles.container__icon}`} />
          )}
          {(!isCollapsed || notCollapse) && (
            <div className={styles.container__relative}>
              <span className={styles.container__label}>
                {t(page.label, {
                  app_provider: process.env.NEXT_PUBLIC_BET_NAME,
                })}
              </span>
              {page?.isNew && (
                <div className={styles['container__new-chip']}>
                  {t('new_game')}
                </div>
              )}
            </div>
          )}
        </Link>
      </div>
    </ToolTip>
  );
}
