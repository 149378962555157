import { useUserBonus } from '@/api/user-bonus';
import { UseUserBonusProps } from '@/api/user-bonus/queries/useUserBonus/props';
import { BonusStatusId } from '@/enums/bonusStatusId';

export function useDetectDepositBoosts(
  props?: Pick<UseUserBonusProps, 'enabled'>,
) {
  const { enabled = true } = props ?? {};
  return useUserBonus({
    statusIds: [BonusStatusId.Coupon, BonusStatusId.Spin, BonusStatusId.Ticket],
    enabled,
  });
}
