import { Device } from '@/enums/device';
import { Rank } from '@/models/rank';
import { Nullable } from '@starsoft/common/interfaces';
import { Coin } from '@starsoft/common/models';

export enum CasinoActions {
  HandleInputChange = 'casino/handleInputChange',
  HandleInputProviderChange = 'casino/handleInputProviderChange',
  HandleChangeViewport = 'casino/handleChangeViewport',
  HandleChangeGameMode = 'casino/handleChangeGameMode',
  HandleProviderFilter = 'casino/handleProviderFilter',
  HandleResetAllParams = 'casino/handleResetAllParams',
  SetGameMode = 'casino/setGameMode',
  HandleChangeModeToReal = 'casino/handleChangeModeReal',
  HandleResetSearchProvider = 'casino/handleResetSearchProvider',
  HandleResetSearchGame = 'casino/handleResetSearchGame',
  ToggleSearch = 'casino/toggleSearch',
  SetSearchHistory = 'casino/setSearchHistory',
  ResetSearchHistory = 'casino/resetSearchHistory',
  RemoveSearchHistoryItem = 'casino/removeSearchHistory',
  SetModalSearch = 'casino/setModalSearch',
  SetUserDevice = 'casino/setUserDevice',
  SetHasSlotSession = 'casino/setHasSlotSession',
  SetLevelUp = 'casino/setLevelUp',
  SetPlayCoin = 'casino/setPlayCoin',
}

export enum CasinoGameMode {
  Demo = 'demo',
  Real = 'real',
}
export interface CasinoStateProps {
  searchGame: string;
  useWideViewport: boolean;
  mode: CasinoGameMode;
  providersList: number[];
  searchProvider: string;
  openSearch: boolean;
  searchHistory: string[];
  modalSearch: string;
  device: Device | undefined;
  hasSlotSession: boolean;
  levelUp: Nullable<Rank>;
  gameTags: string[];
  playCoin: Nullable<Coin>;
}

export interface CasinoBasePayload<T extends CasinoActions, V = null> {
  type: T;
  payload: V;
}

export type HandleChangeSearchValuePayload = CasinoBasePayload<
  CasinoActions.HandleInputChange,
  string
>;

export type HandleChangeProviderSearchPayload = CasinoBasePayload<
  CasinoActions.HandleInputProviderChange,
  string
>;

export type HandleProviderFilterPayload = CasinoBasePayload<
  CasinoActions.HandleProviderFilter,
  number
>;

export type SetGameModePayload = CasinoBasePayload<
  CasinoActions.SetGameMode,
  CasinoGameMode
>;

export type SetSearchHistory = CasinoBasePayload<
  CasinoActions.SetSearchHistory,
  string
>;

export type SetModalSearchPayload = CasinoBasePayload<
  CasinoActions.SetModalSearch,
  string
>;

export type RemoveSearchHistoryItemPayload = CasinoBasePayload<
  CasinoActions.RemoveSearchHistoryItem,
  { index: number }
>;

export type SetUserDevicePayload = CasinoBasePayload<
  CasinoActions.SetUserDevice,
  Device | undefined
>;

export type SetPlayCoinPayload = CasinoBasePayload<
  CasinoActions.SetPlayCoin,
  Nullable<Coin>
>;

export type SetHasSlotSession = CasinoBasePayload<
  CasinoActions.SetHasSlotSession,
  boolean
>;

export type SetLevelUp = CasinoBasePayload<
  CasinoActions.SetLevelUp,
  Nullable<Rank>
>;

export type CasinoStatePayload =
  | HandleChangeSearchValuePayload
  | HandleChangeProviderSearchPayload
  | HandleProviderFilterPayload
  | SetGameModePayload
  | CasinoBasePayload<CasinoActions.HandleChangeGameMode>
  | CasinoBasePayload<CasinoActions.HandleResetAllParams>
  | CasinoBasePayload<CasinoActions.HandleChangeModeToReal>
  | CasinoBasePayload<CasinoActions.HandleResetSearchProvider>
  | CasinoBasePayload<CasinoActions.HandleResetSearchGame>
  | CasinoBasePayload<CasinoActions.HandleChangeViewport>
  | CasinoBasePayload<CasinoActions.ToggleSearch>
  | SetSearchHistory
  | CasinoBasePayload<CasinoActions.ResetSearchHistory>
  | RemoveSearchHistoryItemPayload
  | SetModalSearchPayload
  | SetUserDevicePayload
  | SetHasSlotSession
  | SetPlayCoinPayload
  | SetLevelUp;
