import { useQuery } from '@tanstack/react-query';
import { UserBonusQueryKeys } from '../../queryKeys';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { fetchUserBonusById } from './service';
import { UseUserBonusByIdProps, UseUserBonusByIdQueryKeyProps } from './props';
import { useMemo } from 'react';
import { useUserAccount } from '@/api/user/queries';
import { UserBonus } from '@/models/userBonus';
import { UserBonusCheckpointStatus } from '@/enums/userBonusCheckpointStatus';

export function useUserBonusById({
  id,
  refetchOnMount = true,
}: UseUserBonusByIdProps) {
  const { isAuthorized } = useUserAccount();

  const queryKey: UseUserBonusByIdQueryKeyProps = [UserBonusQueryKeys.ById, id];

  const { data, refetch, isPending, error, fetchStatus, ...rest } = useQuery<
    UserBonus,
    AxiosError<GenericError>,
    UserBonus,
    UseUserBonusByIdQueryKeyProps
  >({
    queryKey,
    queryFn: fetchUserBonusById,
    enabled: isAuthorized && !!id,
    staleTime: 5 * 1000,
    refetchOnMount,
  });

  const curretCheckpointIndex: number = useMemo(
    () =>
      data?.checkpoints?.findIndex(
        checkpoint => checkpoint?.status == UserBonusCheckpointStatus.Created,
      ),
    [data],
  );

  return {
    ...rest,
    error: error as AxiosError<GenericError>,
    refetch,
    isLoading:
      isPending && fetchStatus !== 'idle' && typeof window != 'undefined',
    userBonus: data,
    curretCheckpointIndex,
  };
}
