import { useResolvedTheme } from '@/hooks/theme/useResolvedTheme';
import { Tabs } from '@starsoft/common/components';
import { SettingLayoutComponentId } from '@starsoft/common/models';
import { ComponentTypeId } from '@starsoft/common/models';
import { TabsProps } from '@starsoft/common/src/components/Tabs/props';

export function TabsWithVersion<T>({
  ...props
}: Omit<TabsProps<T>, 'version'>) {
  const { components } = useResolvedTheme();
  const version: SettingLayoutComponentId = components.get(
    ComponentTypeId.Tabs,
  );

  return <Tabs version={version} {...props} />;
}
