import {
  UserBonusActions,
  UserBonusStatePayload,
  UserBonusStateProps,
} from './props';

let initialState: UserBonusStateProps = {
  hideInactive: false,
};

export default function userBonusReducer(
  state: UserBonusStateProps = initialState,
  action: UserBonusStatePayload,
): UserBonusStateProps {
  switch (action.type) {
    case UserBonusActions.ToggleInactive:
      return {
        ...state,
        hideInactive: !!action.payload ? action.payload : !state.hideInactive,
      };
    default:
      return state;
  }
}
