import { useEffect, useState } from 'react';
import BalanceModal from '../BalanceModal';
import { useWalletsMe } from '@/api/wallets';
import BalanceButtonSkeleton from './Skeleton';
import { WalletService } from '@starsoft/common/services';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/lib/store';
import { collapseWallet, setIsUpdating } from '@/lib/store/wallet/actions';
import useTranslation from 'next-translate/useTranslation';
import { useUserAccount } from '@/api/user/queries';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { ModalsKey } from '@/enums/modalsKey';
import {
  Button,
  ErrorChip,
  Image,
  RichTooltip,
} from '@starsoft/common/components';
import { useConversionCoin } from '@/hooks/useConversionCoin';
import { AxiosError } from 'axios';
import { Coin, CurrencyOptions, GenericError } from '@starsoft/common/models';
import { queryClient } from '@/lib/providers/TanstackQueryProvider';
import { UserTagManagerEventsQueryKeys } from '@/api/user-tag-manager-events/queryKeys';
import { Nullable } from '@starsoft/common/interfaces';
import styles from './styles.module.scss';
import useTransactionsNavigation from '@/hooks/useTransacionsNavigation';
import { getMoneyOrAmount } from '@/utils/account/getMoneyOrAmount';
import { useCountUp, useIsMobile } from '@starsoft/common/hooks';
import { usdFormatting } from '@starsoft/common/constants';
import { MoneyService as Money } from '@starsoft/common/services';

export default function BalanceButton() {
  const { t } = useTranslation('common');
  const { user } = useUserAccount();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { navigateToModal } = useModalNavigation();
  const open: boolean = useSelector(
    (state: RootState) => state.wallet.collapsed,
  );
  const hasSlotSession: boolean = useSelector(
    (state: RootState) => state.casino.hasSlotSession,
  );
  const { navigateToTransactionModal } = useTransactionsNavigation();
  const isUpading: boolean = useSelector(
    (state: RootState) => state.wallet.isUpdating,
  );
  const {
    wallet,
    error: errorWallet,
    refetch: refetchWallet,
    isLoading: isLoadingWallet,
  } = useWalletsMe({
    refetchOnWindowFocus: 'always',
  });
  const {
    coin: coinConversion,
    error: errorFiatConversionCoin,
    refetch: refetchFiatConversionCoin,
    isPending: isLoadingFiatConversionCoin,
  } = useConversionCoin();
  const isLoading: boolean = isLoadingWallet || isLoadingFiatConversionCoin;
  const error: Nullable<AxiosError<GenericError>> =
    errorWallet ?? errorFiatConversionCoin;

  const balance: number = coinConversion
    ? WalletService.convertValueToCoin({
        value: getMoneyOrAmount(wallet?.currentAccount?.balance) ?? 0,
        valueCoinPrice: wallet?.currentAccount?.coin?.price as number,
        conversionCoinPrice: coinConversion.price,
      })
    : (getMoneyOrAmount(wallet?.currentAccount?.balance) ?? 0);
  const [previousBalance, setPreviousBalance] = useState<number>(balance);
  const [addedAmount, setAddedAmount] = useState<number>(0);
  const [isAnimatingIncrease, setIsAnimatingIncrease] = useState(false);

  const coin: Coin | CurrencyOptions = coinConversion
    ? coinConversion
    : (wallet?.currentAccount?.coin ?? usdFormatting);

  const { value, reset } = useCountUp({
    end: balance,
    start: previousBalance,
    duration: 1,
    delay: balance > previousBalance && isUpading ? 1000 : 0,
    formatter: value => {
      return WalletService.maskCurrency({
        amount: value ?? 0,
        coin,
        hideSymbol: !coin || !isMobile,
      });
    },
    onComplete: () => {
      setPreviousBalance(balance);
      dispatch(setIsUpdating(false));

      if (balance > previousBalance) {
        queryClient.invalidateQueries({
          queryKey: [UserTagManagerEventsQueryKeys.Primary],
        });
      }
    },
  });

  const formattedAddedAmount = WalletService.maskCurrency({
    amount: addedAmount,
    coin,
    hideSymbol: !coin,
  });

  function handleUseCountUpReset() {
    if (isAnimatingIncrease && isUpading) {
      return;
    }

    reset();
  }

  useEffect(handleUseCountUpReset, [
    wallet,
    reset,
    isAnimatingIncrease,
    isUpading,
  ]);

  function onBalanceChange() {
    if (!isUpading) {
      return;
    }

    if (balance > previousBalance) {
      const addedAmount: Money = new Money({
        amount: balance,
        coin,
      });
      addedAmount.sub(previousBalance);

      setAddedAmount(addedAmount.amount);
      setIsAnimatingIncrease(true);
    }
  }

  useEffect(onBalanceChange, [balance, previousBalance, isUpading, coin]);

  function handleToggleModal() {
    dispatch(collapseWallet());
  }

  function handleAnimationEnd() {
    setPreviousBalance(balance);
    setIsAnimatingIncrease(false);
    setAddedAmount(0);
  }

  function handleRefetch() {
    if (errorWallet) {
      refetchWallet();
    }

    if (errorFiatConversionCoin) {
      refetchFiatConversionCoin();
    }
  }

  function handleDeposit() {
    if (!user) {
      navigateToModal(ModalsKey.Login);
      return;
    }
    navigateToTransactionModal(ModalsKey.Deposit);
  }

  if (isLoading) {
    return <BalanceButtonSkeleton />;
  }

  if (error) {
    return (
      <div className={styles.container}>
        <ErrorChip action={handleRefetch} />
      </div>
    );
  }

  return (
    <RichTooltip
      open={open}
      onClose={handleToggleModal}
      className={styles.container__tooltip}
      content={<BalanceModal />}
    >
      <div className={styles.container}>
        <button
          className={styles.container__balance}
          onClick={handleToggleModal}
        >
          <div
            className={`${styles.container__row} ${styles['container__row--start']}`}
          >
            <Image
              className={styles.container__balance__coin}
              alt={`coin-icon-${wallet?.currentAccount?.coin?.name}`}
              format="square"
              src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/coins/rounded/${wallet?.currentAccount?.coin?.image}`}
              secondarySkeleton
            />
            <div
              className={`${styles.container__row} ${styles['container__row--small']}`}
            >
              <span className={`${styles.container__balance__symbol}`}>
                {wallet?.currentAccount?.coin?.code}
              </span>
            </div>
          </div>

          <div
            className={`${styles.container__balance__value} ${
              hasSlotSession
                ? styles['container__balance__value--invisible']
                : ''
            }  ${styles[`container__balance__value--${wallet?.currentAccount?.coin?.type}`]}`}
          >
            {value}

            {isAnimatingIncrease && (
              <div
                className={styles['container__add-balance']}
                onAnimationEnd={handleAnimationEnd}
              >
                {formattedAddedAmount}
              </div>
            )}
          </div>

          <i
            className={`fas fa-chevron-down ${styles.container__balance__chevron} ${open ? styles['container__balance__chevron--open'] : ''}`}
          />
        </button>
        <Button className={styles.container__deposit} onClick={handleDeposit}>
          <i className="fa-duotone fa-wallet" />
          <span>{t('tab_deposit')}</span>
        </Button>
      </div>
    </RichTooltip>
  );
}
