import { Account } from '@starsoft/common/models';
import { useMemo } from 'react';
import { BalanceModalContentAccountsProps } from './props';
import CurrencyButton from '@/components/CurrencyButton';
import { ErrorCard, filterOptions } from '@starsoft/common/components';
import EmptySearch from '@/components/core/InputSearch/Empty';

export default function BalanceModalContentAccounts({
  error,
  search,
  refetch,
  onClick,
  accounts,
  isLoading,
  isLoadingAction,
  disabledCurrent,
}: BalanceModalContentAccountsProps) {
  function handleCoinsFiltering() {
    let baseAccounts: Account[] = [...(accounts ?? [])];

    if (search) {
      baseAccounts = filterOptions(baseAccounts, search, [
        'coin.name',
        'coin.code',
      ]);
    }

    return baseAccounts;
  }

  const filteredAccounts: Account[] = useMemo(handleCoinsFiltering, [
    accounts,
    search,
  ]);

  const Accounts: JSX.Element[] = useMemo(
    () =>
      filteredAccounts?.map(item => (
        <CurrencyButton
          isBalanceModal
          account={item}
          loading={isLoadingAction}
          onClick={onClick}
          disabledCurrent={disabledCurrent}
          key={`currency_button_item_${item.id}`}
        />
      )),
    [filteredAccounts, isLoadingAction, onClick, disabledCurrent],
  );
  const Skeletons: JSX.Element[] = useMemo(
    () =>
      Array.from({ length: 30 })?.map((_, index) => (
        <CurrencyButton
          isSkeleton
          account={null}
          key={`currency_button_item_skeleton_${index}`}
        />
      )),
    [],
  );

  if (error) {
    return <ErrorCard error={error} refetch={refetch} />;
  }

  if (accounts.length == 0 && !isLoading) {
    return <EmptySearch search={search} />;
  }

  return isLoading ? Skeletons : Accounts;
}
