import { pageSections } from '@/constants/sections';
import SidebarItem from './Item';
import SupportSection from './SupportSection';
import ReferSection from './ReferSection';
import LogoutSection from './LogoutSection';
import VipClubSection from './VipClubSection';
import TournamentSection from './TournamentSection';
import { useMemo } from 'react';
import useLayoutSettings from '@/hooks/theme/useLayoutSettings';
import SidebarContentBonus from './Bonus';
import { StaggerAnimation } from '@starsoft/common/components';

export default function SidebarContent() {
  const { isCollapsed } = useLayoutSettings();
  const Sections: JSX.Element[] = useMemo(
    () =>
      pageSections.map((section, i) => (
        <SidebarItem key={`sidebar_section_item_${i}`} section={section} />
      )),
    [],
  );

  return (
    <StaggerAnimation direction="column" stagger={0.1} staggerDirection="up">
      {isCollapsed && <SidebarContentBonus />}
      {Sections}
      <VipClubSection />
      <TournamentSection />
      <ReferSection />
      <SupportSection />
      <LogoutSection />
    </StaggerAnimation>
  );
}
