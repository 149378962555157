import styles from './styles.module.scss';
import NotificationButton from '@/components/Notifications/NotificationButton';
import { IconButton } from '@starsoft/common/components';
import { UserRankCard } from '@/components/UserRankCard';
import { AuthService } from '@/services/AuthService';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { ModalsKey } from '@/enums/modalsKey';
import { Setting } from '@starsoft/common/models';
import { useResolvedTheme } from '@/hooks/theme/useResolvedTheme';
import { ComponentTypeId } from '@starsoft/common/models';

export default function HeaderCommonButtons({ setting }: { setting: Setting }) {
  const { components } = useResolvedTheme(setting);
  const userPlayerId = components.get(ComponentTypeId.UserProfileChip);

  const { navigateToModal } = useModalNavigation();

  const isLogged = AuthService.isAuthorized();

  function onClick() {
    navigateToModal(ModalsKey.Language);
  }

  if (!isLogged) {
    return null;
  }

  return (
    <>
      <div className={styles.content__desktop__only}>
        <NotificationButton />
        <IconButton onClick={onClick}>
          <i className="fad fa-globe fa-swap-opacity" />
        </IconButton>
      </div>
      <UserRankCard id={userPlayerId} />
    </>
  );
}
