import { LocalStorageKey } from '@/enums/localStorageKey';
import { WalletStatePayload, WalletStateProps, WalletActions } from './props';

const initialState: WalletStateProps = {
  collapsed: false,
  hiddenSmalls:
    typeof window !== 'undefined'
      ? localStorage.getItem(LocalStorageKey.HiddenSmalls) === 'true'
      : false,
  convertToCoin:
    typeof window !== 'undefined'
      ? localStorage.getItem(LocalStorageKey.ConvertToCoin) === 'true'
      : false,
  conversionCoinId:
    typeof window !== 'undefined'
      ? localStorage.getItem(LocalStorageKey.ConversionCoinId)
        ? Number(localStorage.getItem(LocalStorageKey.ConversionCoinId))
        : null
      : null,
  isUpdating: false,
};

export default function walletReducer(
  state: WalletStateProps = initialState,
  action: WalletStatePayload,
): WalletStateProps {
  switch (action.type) {
    case WalletActions.Collapse: {
      return {
        ...state,
        collapsed: !state.collapsed,
      };
    }
    case WalletActions.SetHiddenValue: {
      if (typeof window !== 'undefined') {
        localStorage.setItem(
          LocalStorageKey.HiddenSmalls,
          `${!state.hiddenSmalls}`,
        );
      }

      return {
        ...state,
        hiddenSmalls: !state.hiddenSmalls,
      };
    }

    case WalletActions.ToggleConvertToCoin: {
      if (typeof window !== 'undefined') {
        localStorage.setItem(
          LocalStorageKey.ConvertToCoin,
          `${!state.convertToCoin}`,
        );
      }

      return {
        ...state,
        convertToCoin: !state.convertToCoin,
      };
    }
    case WalletActions.SetConversionCoin: {
      if (typeof window !== 'undefined') {
        localStorage.setItem(
          LocalStorageKey.ConversionCoinId,
          `${action.payload}`,
        );
      }

      return {
        ...state,
        conversionCoinId: action.payload,
      };
    }
    case WalletActions.SetIsUpdating: {
      return {
        ...state,
        isUpdating: action.payload,
      };
    }

    default:
      return state;
  }
}
