import { RootState } from '@/lib/store';
import { getMoneyOrAmount } from '@/utils/account/getMoneyOrAmount';
import { Account } from '@starsoft/common/models';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export function useCoinFiltering(accounts: Account[]) {
  const hiddenSmalls: boolean = useSelector(
    (state: RootState) => state.wallet.hiddenSmalls,
  );

  function handleCoinsFiltering() {
    let baseAccounts: Account[] = [...(accounts ?? [])];

    if (hiddenSmalls) {
      baseAccounts = baseAccounts.filter(
        account => getMoneyOrAmount(account.balance) > 0,
      );
    }

    return baseAccounts;
  }

  return useMemo(handleCoinsFiltering, [accounts, hiddenSmalls]);
}
