import { useMutation, useQueryClient } from '@tanstack/react-query';
import { selectCoin } from './service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/lib/store';
import { collapseWallet } from '@/lib/store/wallet/actions';
import { ChangeCurrentCoinResponseDto } from '@/services/dto/wallets/change-current-coin-response.dto';
import { setAuthCurrentCoin } from '@/lib/store/auth/actions';
import { Wallet } from '@/models/wallet';
import { WalletsQueryKeys } from '../../queryKeys';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { ErrorService } from '@/services/ErrorService';
import { handleChangeAmount } from '@/lib/store/games-preferences/actions';
import { setHasSlotSession } from '@/lib/store/casino/actions';

export function useSelectCurrentCoin() {
  const dispatch = useDispatch();
  const open: boolean = useSelector(
    (state: RootState) => state.wallet.collapsed,
  );
  const queryClient = useQueryClient();

  function onSuccess(data: ChangeCurrentCoinResponseDto) {
    dispatch(setAuthCurrentCoin(data.currentAccount.coin));
    queryClient.setQueriesData<Wallet | undefined>(
      { queryKey: [WalletsQueryKeys.Me] },
      oldData => {
        if (!oldData) {
          return oldData;
        }

        return {
          ...oldData,
          currentAccount: data.currentAccount,
        };
      },
    );

    dispatch(handleChangeAmount(0));
    dispatch(setHasSlotSession(false));

    if (open) {
      dispatch(collapseWallet());
    }
  }

  function onError(error: AxiosError<GenericError>) {
    ErrorService.onError(error);
  }

  const {
    mutate,
    isPending: isLoading,
    isError,
  } = useMutation({
    mutationFn: selectCoin,
    onSuccess,
    onError,
  });

  return {
    mutate,
    isLoading,
    isError,
  };
}
