import { ModalsKey } from '@/enums/modalsKey';
import { usePathname } from '@starsoft/common/hooks';
import { useCurrentModal } from './modals/useCurrentModal';
import { PageProps } from '@/types/page/props';
import { useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';

export function useIsPageActive(page: PageProps) {
  const pathname: string = usePathname();
  const modal: ModalsKey | undefined = useCurrentModal();
  const { lang } = useTranslation();
  const isActive: boolean = useMemo(() => {
    if (page?.modal) {
      return page.modal.includes(modal);
    }

    return page.paths.some(path =>
      path === '/'
        ? path === pathname.replace(lang, '')
        : pathname.endsWith(path),
    );
  }, [pathname, page, lang, modal]);

  return isActive;
}
