import { SettingsService } from '@starsoft/common/services/SettingsService';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export function usePathname(): string {
  const { asPath } = useRouter();
  const pathname: string = useMemo(() => {
    const url = new URL(asPath, SettingsService.env.DEPLOY_URL);
    return url.pathname;
  }, [asPath]);

  return pathname;
}
