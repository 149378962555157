import { useComponentVariant } from '@/hooks/theme/useComponentVariant';
import { SettingLayoutComponentId } from '@starsoft/common/models';
import { ComponentTypeId } from '@starsoft/common/models';
import { RewardProps } from './props';
import dynamic from 'next/dynamic';

const RewardV1 = dynamic(() => import('./V1'), { ssr: true });
const RewardV2 = dynamic(() => import('./V2'), { ssr: true });

export default function Reward(props: RewardProps): JSX.Element {
  const version: SettingLayoutComponentId = useComponentVariant(
    ComponentTypeId.RewardIcon,
  );

  switch (version) {
    case SettingLayoutComponentId.RewardIconV1:
      return <RewardV1 {...props} />;
    case SettingLayoutComponentId.RewardIconV2:
      return <RewardV2 {...props} />;
    default:
      return <RewardV1 {...props} />;
  }
}
