import { store } from '@/lib/store';
import { CasinoNotificationsService } from '@/services/NotificationsService';
import { useMemo } from 'react';

export function useNotificationService() {
  const NotificationsService = useMemo(
    () => new CasinoNotificationsService(store),
    [],
  );

  return {
    NotificationsService,
  };
}
