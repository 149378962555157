import { RootState } from '@/lib/store';
import { useSelector } from 'react-redux';
import { platformAssets } from '@/utils/assets';
import Link from '@/components/core/Link';
import styles from './styles.module.scss';
import { Image } from '@starsoft/common/components';
import HeaderAuthButtons from './Buttons/Auth';
import HeaderCommonButtons from './Buttons/Common';
import BalanceButton from '../BalanceButton';
import GameSearchButton from './GameSearchButton';
import { useShowNavigation } from '@/hooks/useShowNavigation';
import SlotBackButton from './SlotBackButton';
import { AuthService } from '@/services/AuthService';
import BonusLink from './BonusLink';
import { Setting } from '@starsoft/common/models';
import { useIsMobile } from '@starsoft/common/hooks';
import useLayoutSettings from '@/hooks/theme/useLayoutSettings';
import BonusChip from '../Bonus/BonusChip';
import { AnimatePresence } from 'framer-motion';
import SidebarHeaderContentLogo from '../SideBar/Header/Content/Logo';

function Header({ setting }: { setting: Setting }) {
  const collapsed: boolean = useSelector(
    (state: RootState) => state.sideBar.collapsed,
  );
  const isMobile = useIsMobile();
  const showNavigation = useShowNavigation();

  const { isV2LayoutVariant } = useLayoutSettings();

  return (
    <header
      suppressHydrationWarning
      className={`${styles.container} ${collapsed ? styles['container--collapsed'] : ''} ${!showNavigation ? styles['container--hidden'] : ''} ${isV2LayoutVariant ? styles['container--v2'] : ''}`}
    >
      <div className={styles.container__content}>
        <div className={styles.container__content__mobile}>
          {showNavigation ? (
            <Link href="/">
              <Image
                format="square"
                width={65}
                height={65}
                className={styles.container__content__mobile__logo}
                src={platformAssets.logo.web.dark}
                alt="mobile-logo"
              />
            </Link>
          ) : (
            <div className={styles['mobile-only']}>
              <SlotBackButton />
            </div>
          )}
          <BonusLink />
        </div>

        <div className={styles.container__content__wrapper}>
          <div className={styles['container__desktop-only']}>
            {isV2LayoutVariant && !isMobile && <SidebarHeaderContentLogo />}
          </div>

          <AnimatePresence>
            <BonusChip />
          </AnimatePresence>
        </div>

        {isMobile && (
          <AnimatePresence>
            <BonusChip />
          </AnimatePresence>
        )}

        {AuthService.isAuthorized() && <BalanceButton />}
        <div className={styles.container__content__row}>
          <div className={styles['container__desktop-only']}>
            <GameSearchButton />
          </div>
          <HeaderAuthButtons />
          <HeaderCommonButtons setting={setting} />
        </div>
      </div>
    </header>
  );
}

export default Header;
