import {
  LimboActions,
  LimboEnterBetSuccessPayload,
  LimboPredictMultiplierPayload,
  LimboProps,
} from './props';
import { AudioService } from '@/services/AudioService';

const initialState: LimboProps = {
  bet: null,
  limboMultiplier: 1,
  winChance: '',
  animate: false,
  predictMultiplier: 2,
};

export default function reducer(
  state: LimboProps = initialState,
  action: any,
): LimboProps {
  switch (action.type) {
    case LimboActions.EnterBetSuccess:
      if (!AudioService.isGamesMuted()) {
        AudioService.limboFly();
      }

      return {
        ...state,
        bet: (action as LimboEnterBetSuccessPayload).payload.bet,
        animate: true,
        limboMultiplier: (action as LimboEnterBetSuccessPayload).payload.bet
          ?.crashpointBet?.crashpoint as number,
      };
    case LimboActions.HandleAnimate:
      return {
        ...state,
        animate: !state.animate,
      };
    case LimboActions.HandleChangePredictMultiplier:
      return {
        ...state,
        predictMultiplier: (action as LimboPredictMultiplierPayload).payload
          .multiplier,
      };
    case LimboActions.HandleReset:
      return initialState;
    default:
      return state;
  }
}
