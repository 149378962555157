export enum SettingLayoutComponentId {
  DefaultLogin = 1,
  MinimalLogin = 2,
  GameCardV1 = 3,
  GameCardV2 = 4,
  GameCardV3 = 5,
  ToastyV1 = 6,
  ToastyV2 = 7,
  NotificationsV1 = 8,
  NotificationsV2 = 9,
  BetAmountInputV1 = 10,
  BetAmountInputV2 = 11,
  BetAmountInputV3 = 12,
  BetAmountInputV4 = 13,
  LoginV3 = 14,
  LayoutV1 = 15,
  LayoutV2 = 16,
  TabsV1 = 17,
  TabsV2 = 18,
  TabsV3 = 19,
  ProfileRankCardV1 = 20,
  ProfileRankCardV2 = 21,
  UserProfileChipV1 = 22,
  UserProfileChipV2 = 23,
  UserProfileChipV3 = 24,
  UserProfileChipV4 = 25,
  TournamentCardV1 = 26,
  TournamentCardV2 = 27,
  TournamentCardV3 = 28,
  VipClubCardV1 = 29,
  VipClubCardV2 = 30,
  MainBannerV1 = 31,
  MainBannerV2 = 32,
  LayoutV3 = 33,
  RewardCardV1 = 34,
  RewardCardV2 = 35,
  ToastyV3 = 36,
  RewardIconV1 = 37,
  RewardIconV2 = 38,
  GameCountdownV1 = 39,
  GameCountdownV2 = 40,
  GameVictoryBannerV1 = 41,
  GameVictoryBannerV2 = 42,
  VipClubOverviewV1 = 43,
  VipClubOverviewV2 = 44,
}
