import { Socket } from 'socket.io-client';
import { useSocketIoFactory } from './useSocketIoFactory';
import { Nullable } from '@starsoft/common/interfaces';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { UserBonusQueryKeys } from '@/api/user-bonus/queryKeys';
import { deleteCookie } from 'cookies-next';
import { CookiesKey } from '@/enums/cookiesKey';
import { store } from '@/lib/store';
import { toggleUserBonusInactive } from '@/lib/store/user-bonus/actions';

export default function useUserBonusSocket() {
  const socket: Nullable<Socket> = useSocketIoFactory({
    gateway: 'user-bonus',
    isProtected: true,
  });
  const queryClient: QueryClient = useQueryClient();

  const onConnect: VoidFunction = useCallback(() => {
    if (!socket) {
      return;
    }

    socket.emit('join');
  }, [socket]);

  const onCreate: VoidFunction = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [UserBonusQueryKeys.Primary],
    });
    deleteCookie(CookiesKey.HideBonusCardMobile);
    store.dispatch(toggleUserBonusInactive(false));
  }, [queryClient]);

  function setServerOnEvents() {
    if (!socket) return;

    socket.on('connect', onConnect);
    socket.on('create', onCreate);
  }

  useEffect(setServerOnEvents, [socket, onConnect, onCreate]);
}
