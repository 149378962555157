import {
  GamesPreferencesActions,
  HandleAnimatePayload,
  HandleChangeAmountPayload,
  HandleChangeMultiplierPayload,
  SetDoubleAmountPayload,
  SetHalfAmountPayload,
} from './props';

export function handleChangeAmount(amount: number): HandleChangeAmountPayload {
  return {
    type: GamesPreferencesActions.HandleChangeAmount,
    payload: {
      amount,
    },
  };
}

export function handleChangeMultiplier(
  multiplier: string,
): HandleChangeMultiplierPayload {
  return {
    type: GamesPreferencesActions.HandleChangeMultiplier,
    payload: {
      multiplier,
    },
  };
}

export function setDoubleAmount(): SetDoubleAmountPayload {
  return {
    type: GamesPreferencesActions.SetDoubleAmount,
  };
}

export function setHalfAmount(): SetHalfAmountPayload {
  return {
    type: GamesPreferencesActions.SetHalfAmount,
  };
}

export function handleToggleGameButtonAnimation(
  animate: boolean,
): HandleAnimatePayload {
  return {
    type: GamesPreferencesActions.HandleToggleAnimate,
    payload: {
      animate,
    },
  };
}
