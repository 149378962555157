import { UserRewardsQueryKeys } from '../../queryKeys';
import {
  UseUserRewardsByRewardProps,
  UseUserRewardsByRewardQueryKeyProps,
} from './props';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { fetchUserRewardsByReward } from './service';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { UserReward } from '@/models/userReward';
import { useMemo } from 'react';
import { Earnback } from '@/models/userReward/earnback';
import { LevelUp } from '@/models/userReward/levelUp';
import { RewardId } from '@/enums/reward-id';
import {
  useInfiniteQueryCallbacks,
  useInfiniteQueryReset,
} from '@starsoft/common/hooks';

export function useUserRewardsByReward(options?: UseUserRewardsByRewardProps) {
  const { rewardId, refetchOnMount = true } = options ?? {};
  const queryKey: UseUserRewardsByRewardQueryKeyProps = [
    UserRewardsQueryKeys.ByReward,
    {
      rewardId: rewardId as number,
      limit: 20,
    },
  ];

  const { getNextPageParam, getPreviousPageParam } =
    useInfiniteQueryCallbacks();
  useInfiniteQueryReset(queryKey);

  const { data, isPending, error, refetch, ...rest } = useInfiniteQuery<
    PaginatedResponse<UserReward>,
    AxiosError<GenericError>,
    InfiniteData<PaginatedResponse<UserReward>>,
    UseUserRewardsByRewardQueryKeyProps
  >({
    queryKey,
    queryFn: fetchUserRewardsByReward,
    getNextPageParam,
    getPreviousPageParam,
    initialPageParam: 1,
    enabled: !!rewardId,
    staleTime: 5 * 1000,
    refetchInterval: 60 * 1000 * 10,
    refetchOnMount,
  });

  const flatRewards = data?.pages?.flatMap(page => page?.data);

  const rewards = useMemo(
    () =>
      flatRewards?.map(userReward => {
        switch (userReward?.reward?.id) {
          case RewardId.LevelUp:
            return userReward?.levelUp as LevelUp;
          default:
            return userReward?.earnback as Earnback;
        }
      }),
    [flatRewards],
  );

  const reward = flatRewards?.[0]?.reward;

  return {
    ...rest,
    userRewards: flatRewards,
    rewards,
    reward,
    isLoading: isPending,
    error,
    refetch,
  };
}
