import { TournamentStatus } from '@/models/tournament/status';
import { Nullable } from '@starsoft/common/interfaces';

export type TournamentStateProps = {
  tab: Nullable<TournamentStatus>;
  query: string;
};

export enum TournamentsActions {
  SetCurrentTab = 'tournaments/set-current-tab',
  SetTournamentQuery = 'tournaments/set-tournaments-query',
}

export interface TournamentsBasePayload<
  T extends TournamentsActions,
  V = null,
> {
  type: T;
  payload: V;
}

export type SetCurrentTournamentTabPayload = TournamentsBasePayload<
  TournamentsActions.SetCurrentTab,
  Nullable<TournamentStatus>
>;

export type SetTournamentsQueryPayload = TournamentsBasePayload<
  TournamentsActions.SetTournamentQuery,
  string
>;

export type TournamentsStatePayload =
  | SetCurrentTournamentTabPayload
  | SetTournamentsQueryPayload;
