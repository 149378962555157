import { useCallback, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import { Account } from '@/models/account';
import { useSocketIoFactory } from './useSocketIoFactory';
import { Coin } from '@/models/coin';
import { Wallet } from '@/models/wallet';
import { WalletsQueryKeys } from '@/api/wallets/queryKeys';
import { InvestmentWalletQueryKeys } from '@/api/investment-wallet/queryKeys';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { AccountQueryKeys } from '@starsoft/common/api/account';

export default function useCoinsSocket() {
  const queryClient: QueryClient = useQueryClient();
  const socket: Socket | null = useSocketIoFactory({
    gateway: 'coins',
  });

  const updateAccounts = useCallback(
    (accounts: Account[] | undefined, coins: Coin[]): Account[] => {
      if (!accounts) {
        return [];
      }

      return accounts?.map(account => {
        const updatedCoin = coins?.find(coin => coin.id === account.coin.id);

        return {
          ...account,
          coin: updatedCoin ? updatedCoin : account.coin,
        };
      });
    },
    [],
  );

  const updateWallet = useCallback(
    (oldData: Wallet | undefined, coins: Coin[]) => {
      if (!oldData) {
        return oldData;
      }

      const updatedAccounts: Account[] = updateAccounts(
        oldData?.accounts,
        coins,
      );

      const updatedCurrentAccountCoin = coins?.find(
        coin => coin.id === oldData?.currentAccount?.coin?.id,
      );

      const updatedCurrentAccount: Account = {
        ...oldData?.currentAccount,
        coin: updatedCurrentAccountCoin
          ? updatedCurrentAccountCoin
          : oldData?.currentAccount?.coin,
      };

      return {
        ...oldData,
        accounts: updatedAccounts,
        currentAccount: updatedCurrentAccount,
      };
    },
    [updateAccounts],
  );

  const onPriceUpdate = useCallback(
    (coins: Coin[]) => {
      queryClient.setQueriesData<Wallet>(
        { queryKey: [WalletsQueryKeys.Me] },
        oldData => updateWallet(oldData, coins),
      );
      queryClient.setQueriesData<Wallet>(
        { queryKey: [InvestmentWalletQueryKeys.GetInvestmentWallet] },
        oldData => updateWallet(oldData, coins),
      );
      queryClient.setQueriesData<Account[]>(
        { queryKey: [AccountQueryKeys.Cryptos] },
        oldData => updateAccounts(oldData, coins),
      );
      queryClient.setQueriesData<Account[]>(
        { queryKey: [AccountQueryKeys.Fiats] },
        oldData => updateAccounts(oldData, coins),
      );
    },
    [updateAccounts, updateWallet, queryClient],
  );

  function setServerOnEvents() {
    if (!socket) return;

    socket.on('price-update', onPriceUpdate);
  }

  useEffect(setServerOnEvents, [socket, queryClient, onPriceUpdate]);
}
