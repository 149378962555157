import { RootState } from '@/lib/store';
import { useSelector } from 'react-redux';
import CollapseButton from './CollapseButton';
import styles from './styles.module.scss';
import SidebarHeaderContent from './Content';
import useLayoutSettings from '@/hooks/theme/useLayoutSettings';

export default function SidebarHeader() {
  const collapsedSidebar: boolean = useSelector(
    (state: RootState) => state.sideBar.collapsed,
  );
  const { isV2LayoutVariant } = useLayoutSettings();

  return (
    <div
      className={`${styles.container} ${collapsedSidebar ? styles['container--collapsed'] : ''} ${isV2LayoutVariant ? styles['container--variant2'] : ''}`}
    >
      <CollapseButton />
      <SidebarHeaderContent />
    </div>
  );
}
