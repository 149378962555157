import baseAPI from '@/api';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { Reward } from '@/models/userReward/reward';

export async function fetchUserRewards() {
  const response = await baseAPI.get<PaginatedResponse<Reward>>(
    '/user-rewards',
    {
      params: {
        limit: 10,
      },
    },
  );
  return response.data;
}
