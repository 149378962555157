import { tabsOptions } from './options';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { usePathname } from '@starsoft/common/hooks';
import styles from './styles.module.scss';
import useTranslation from 'next-translate/useTranslation';
import { TabsWithVersion } from '@/components/core/TabsWithVersion';
import useLayoutSettings from '@/hooks/theme/useLayoutSettings';
import { TabProps } from './props';
import { ToolTip } from '@starsoft/common/components';

export default function SidebarHeaderContentTabs({ showCollapsed }: TabProps) {
  const { push } = useRouter();
  const pathname = usePathname();
  const { t } = useTranslation('common');
  const { isCollapsed: collapsedSidebar } = useLayoutSettings();

  const onTabChange = useCallback(
    (path: string) => {
      push(path);
    },
    [push],
  );

  if (collapsedSidebar && !showCollapsed) {
    return null;
  }

  return (
    <TabsWithVersion
      tabs={tabsOptions}
      setTab={onTabChange}
      invertColors
      isColumn={collapsedSidebar}
      renderTab={({ tab, currentTab, setTab }) => (
        <ToolTip
          visible={collapsedSidebar}
          titleComponent={t(tab?.label) as string}
        >
          <div
            className={`${styles.tab} ${currentTab == tab?.value ? styles['tab--active'] : ''} ${collapsedSidebar ? styles['tab--collapsed'] : ''}`}
            onClick={() => setTab(tab?.value)}
          >
            <i
              className={`${styles.tab__image} ${tab?.icon} ${currentTab == tab?.value ? styles['tab__image--active'] : ''} ${collapsedSidebar ? styles['tab__image--collapsed'] : ''}`}
            />
            <div
              className={`${styles.tab__label} ${collapsedSidebar ? styles['tab__label--collapsed'] : ''}`}
            >
              {t(tab?.label)}
            </div>
          </div>
        </ToolTip>
      )}
      isTiny
      currentTab={pathname}
    />
  );
}
