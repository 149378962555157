import { useQuery } from '@tanstack/react-query';
import { RewardsQueryKeys } from '../../queryKeys';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { Reward } from '@/models/userReward/reward';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { fetchRewards } from './service';
import { UseRewardsProps } from './props';

export function useRewards(options?: UseRewardsProps) {
  const { enabled = true } = options ?? {};
  const queryKey = [RewardsQueryKeys.Primary];

  const { data, isPending, refetch, error, fetchStatus } = useQuery<
    PaginatedResponse<Reward>,
    AxiosError<GenericError>
  >({
    queryKey,
    queryFn: fetchRewards,
    enabled,
    staleTime: 5 * 1000,
  });

  return {
    rewards: data,
    isLoading: isPending && fetchStatus !== 'idle',
    refetch,
    error,
  };
}
