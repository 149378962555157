import useTranslation from 'next-translate/useTranslation';
import Rewards from './Rewards';

import styles from './styles.module.scss';
import { useComponentVariant } from '@/hooks/theme/useComponentVariant';
import { SettingLayoutComponentId } from '@starsoft/common/models';
import { ComponentTypeId } from '@starsoft/common/models';

export default function RewardsSection() {
  const { t } = useTranslation('common');
  const version: SettingLayoutComponentId = useComponentVariant(
    ComponentTypeId.RewardIcon,
  );
  const isV2Version: boolean =
    version === SettingLayoutComponentId.RewardIconV2;

  return (
    <section
      className={`${styles.container} ${isV2Version ? styles['container--v2'] : ''}`}
    >
      <div className={styles.container__row}>
        <i className={`${styles.container__icon} fa-solid fa-tags`} />
        <h6 className={styles.container__title}> {t('promotions_label')}</h6>
      </div>

      <Rewards />
    </section>
  );
}
