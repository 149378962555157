import { useLottiePlayer } from '@starsoft/common/hooks';
import styles from './styles.module.scss';
import useTranslation from 'next-translate/useTranslation';
import SidebarItemSection from '../Item/Section';
import { useRouter } from 'next/router';
import { AuthService } from '@/services/AuthService';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { ModalsKey } from '@/enums/modalsKey';

export default function SidebarContentBonus() {
  const { t } = useTranslation('common');
  const { lottieAnimationRef } = useLottiePlayer({
    path: '/gift-box.json',
  });
  const { navigateToModal } = useModalNavigation();
  const { push } = useRouter();

  function handleNavigate() {
    if (!AuthService.isAuthorized()) {
      navigateToModal(ModalsKey.Login);
      return;
    }

    push(`/bonus`);
  }

  return (
    <SidebarItemSection label={t('rewards_title')} onClick={handleNavigate}>
      <div className={styles.lottie} ref={lottieAnimationRef} />
    </SidebarItemSection>
  );
}
