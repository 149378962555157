import {
  NotificationGroupId,
  NotificationTypeId,
} from '@starsoft/common/enums';
import {
  NotificationsActions,
  NotificationsPayload,
  NotificationsStateProps,
} from './props';
import { Notification } from '@starsoft/common/models';

const initialState: NotificationsStateProps<any> = {
  tab: NotificationGroupId.Inbox,
  isSocketConnected: false,
  notificationsToasty: [],
};

export function notificationsReducer<T>(
  state: NotificationsStateProps<T> = initialState,
  action: NotificationsPayload<T>,
): NotificationsStateProps<T> {
  switch (action.type) {
    case NotificationsActions.AddNotification:
      const isGameBet: boolean =
        //@ts-ignore
        ((action.payload as Notification<T>).type.id as number) ==
        NotificationTypeId.GameBet;
      const notifySound: string = isGameBet
        ? '/audios/enter-bet.wav'
        : '/audios/notification.mp3';

      // AudioService.play(notifySound, 0.3);

      return {
        ...state,
        notificationsToasty: state.notificationsToasty.concat(action.payload),
      };
    case NotificationsActions.RemoveNotification:
      return {
        ...state,
        notificationsToasty: state.notificationsToasty.filter(
          notification => notification.id !== action.payload,
        ),
      };

    case NotificationsActions.SetTab:
      return {
        ...state,
        tab: action.payload,
      };
    case NotificationsActions.SetSocketConnection:
      return {
        ...state,
        isSocketConnected: action.payload,
      };
    default:
      return state;
  }
}
