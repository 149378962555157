import { Input } from '@starsoft/common/components';
import styles from './styles.module.scss';
import useTranslation from 'next-translate/useTranslation';
import { BalanceModalSearchInputProps } from './props';

export default function BalanceModalSearchInput({
  search,
  setSearch,
  isLoading,
  isContainerColor,
}: BalanceModalSearchInputProps) {
  const { t } = useTranslation('common');

  function handleResetInput() {
    setSearch('');
  }

  function handleSearchOptionsChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    setSearch(event.target.value);
  }
  return (
    <div
      className={`${styles.container} ${isContainerColor ? styles['container--container-color'] : ''}`}
    >
      <Input
        icon="fa-solid fa-magnifying-glass"
        showEraserButton={search.length > 0}
        onErase={handleResetInput}
        className={styles.cardContainer__searchContainer__searchOption}
        placeholder={t('search_placeholder')}
        onChange={handleSearchOptionsChange}
        value={search}
        isLoading={isLoading}
        isContainerColor={isContainerColor}
      />
    </div>
  );
}
