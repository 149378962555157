import { useWalletsMe } from '@/api/wallets';
import { WalletService } from '@starsoft/common/services';
import { CurrencyButtonProps } from './props';
import { memo, useMemo } from 'react';
import styles from './styles.module.scss';
import { Image } from '@starsoft/common/components';
import CurrencyButtonSkeleton from './Skeleton';
import { useConversionCoin } from '@/hooks/useConversionCoin';
import { getMoneyOrAmount } from '@/utils/account/getMoneyOrAmount';
import { Coin } from '@starsoft/common/models';
import { MoneyService as Money } from '@starsoft/common/services';

function CurrencyButton({
  account,
  onClick,
  noEffects,
  loading,
  isSkeleton,
  disabledCurrent,
  isBalanceModal = false,
}: CurrencyButtonProps): JSX.Element {
  const { wallet } = useWalletsMe({
    enabled: !loading,
    refetchOnMount: false,
  });
  const { coin: fiatConversionCoin } = useConversionCoin();
  const coin: Coin = fiatConversionCoin ? fiatConversionCoin : account?.coin;
  const amount: number = fiatConversionCoin
    ? WalletService.convertValueToCoin({
        value: getMoneyOrAmount(account?.balance) as number,
        valueCoinPrice: account?.coin?.price as number,
        conversionCoinPrice: fiatConversionCoin.price,
      })
    : (getMoneyOrAmount(account?.balance) as number);

  function getDollarBalance() {
    const balance: Money = new Money({
      amount: account?.balance?.amount,
    });

    return balance.multiply(balance.amount, account?.coin?.price);
  }

  const dollarBalance: number = useMemo(getDollarBalance, [account]);

  if (isSkeleton) {
    return <CurrencyButtonSkeleton />;
  }

  return (
    <button
      className={`${styles.container} ${noEffects ? styles['container--without-effect'] : ''} ${noEffects || disabledCurrent ? false : account?.id == wallet?.currentAccount?.id ? styles['container--current'] : ''}`}
      disabled={loading}
      onClick={() => !noEffects && onClick?.(account?.coin?.id as number)}
      type="button"
    >
      <div className={styles.container__coin}>
        <Image
          className={styles.container__coin__image}
          width={24}
          height={24}
          src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/coins/rounded/${account?.coin?.image}`}
          secondarySkeleton
          alt={`coin ${account?.coin?.name}`}
        />
        <div className={styles.container__coin__text__container}>
          <span
            className={`${styles.container__coin__text} ${isBalanceModal ? styles['container__coin__text--balance-modal'] : ''}`}
          >
            {account?.coin?.code}
          </span>
          {!isBalanceModal && account?.coin?.name}
        </div>
      </div>

      <div className={styles.container__balance}>
        <span
          className={`${styles.container__balance__value} ${isBalanceModal ? styles['container__balance__value--balance-modal'] : ''}`}
        >
          {fiatConversionCoin
            ? WalletService.maskCurrency({
                amount: amount,
                coin,
              })
            : account?.balance?.maskedAmount}
        </span>

        <span className={styles.container__balance__conversion}>
          {WalletService.maskCurrency({
            amount: dollarBalance,
          })}
        </span>
      </div>
    </button>
  );
}

export default memo(CurrencyButton);
