import baseAPI from '@/api';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { Reward } from '@/models/userReward/reward';

export async function fetchRewards() {
  const response = await baseAPI.get<PaginatedResponse<Reward>>('/rewards', {
    params: {
      limit: 10,
    },
  });
  return response.data;
}
