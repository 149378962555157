import { useLottiePlayer } from '@starsoft/common/hooks';
import styles from './styles.module.scss';
import Link from '@/components/core/Link';

export default function BonusLink() {
  const { lottieAnimationRef } = useLottiePlayer({
    path: '/gift-box.json',
  });

  return (
    <Link href="/bonus" className={styles.container} privateRoute>
      <div ref={lottieAnimationRef} />
    </Link>
  );
}
