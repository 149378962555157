import styles from './styles.module.scss';
import { useCallback, useState } from 'react';
import { useSelectCurrentCoin, useWalletsMe } from '@/api/wallets';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import BalanceModalContent from './Content';
import { Account } from '@/models/account';
import HideSmallsSwitch from './HideSmallsSwitch';
import { useCoinFiltering } from '@/hooks/useCoinFiltering';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/lib/store';
import ConvertToCoinSwitch from './ConvertToCoinSwitch';
import { useConversionCoin } from '@/hooks/useConversionCoin';
import useTranslation from 'next-translate/useTranslation';
import { collapseWallet } from '@/lib/store/wallet/actions';
import { AnimatePresence, m } from 'framer-motion';
import { useClickOutside, useIsMobile } from '@starsoft/common/hooks';
import { overlayVariant } from '@starsoft/common/components/AutocompleteMenuSelector/variants';
import { drawerVariants } from '@starsoft/common/components/Drawer/variants';

export default function BalanceModal() {
  const {
    wallet,
    isLoading: isLoadingWallet,
    error: errorWallet,
    refetch: refetchWallet,
  } = useWalletsMe({
    refetchOnMount: 'always',
  });
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const {
    error: errorFiatConversionCoin,
    isPending: isPendingFiatConversionCoin,
    refetch: refetchFiatConversionCoin,
  } = useConversionCoin();
  const { mutate, isLoading: isLoadingSelectCurrentCoin } =
    useSelectCurrentCoin();

  const isLoading: boolean = isLoadingWallet || isPendingFiatConversionCoin;
  const error: AxiosError<GenericError> | undefined =
    errorWallet ?? errorFiatConversionCoin;

  const accounts: Account[] = useCoinFiltering(wallet?.accounts ?? []);

  const [isVisible, setIsVisible] = useState(true);

  function refetch() {
    if (errorWallet) {
      refetchWallet();
    }

    if (errorFiatConversionCoin) {
      refetchFiatConversionCoin();
    }
  }

  const selectCoin = useCallback(
    (coinId: number) => {
      mutate({
        coinId,
      });
    },
    [mutate],
  );

  const isOpen: boolean = useSelector(
    (state: RootState) => state.wallet.collapsed,
  );

  const isMobile: boolean = useIsMobile();

  function handleClose() {
    if (isMobile) {
      setIsVisible(!isVisible);
      setTimeout(() => {
        handleExitComplete();
      }, 200);
    } else {
      setIsVisible(true);
      handleExitComplete();
    }
  }

  function handleExitComplete() {
    if (isVisible) {
      dispatch(collapseWallet());
    }
    setIsVisible(false);
  }

  const ref = useClickOutside({
    open: isOpen,
    onClickOutside: handleClose,
  });

  return (
    <AnimatePresence>
      {isVisible && (
        <>
          <m.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={overlayVariant}
            className={styles.overlay}
          />

          <m.div
            key="drawer"
            initial="hidden"
            animate="visible"
            exit="exit"
            ref={isMobile ? ref : null}
            variants={isMobile ? drawerVariants : null}
            className={styles.cardContainer}
          >
            <div className={styles['cardContainer__header-mobile']}>
              <span className={styles['cardContainer__header-mobile__title']}>
                {t('balance_modal_label_header')}
              </span>
              <div
                onClick={handleClose}
                className={styles['cardContainer__header-mobile__button']}
              >
                <i className="fa-solid fa-xmark" />
              </div>
            </div>
            <BalanceModalContent
              error={error as AxiosError<GenericError>}
              isLoading={isLoading}
              accounts={accounts ?? []}
              refetch={refetch}
              onClick={selectCoin}
              isLoadingAction={isLoadingSelectCurrentCoin}
            />

            <div className={styles.cardContainer__footer}>
              <div className={styles.cardContainer__footer__row}>
                <ConvertToCoinSwitch />
                <HideSmallsSwitch />
              </div>
            </div>
          </m.div>
        </>
      )}
    </AnimatePresence>
  );
}
