import React from 'react';
import { CustomizedToolTip } from './styles';
import { TooltipComponentsProps } from './props';
import styles from './styles.module.scss';
import { TransTextWithDefault } from '../TransTextWithDefault';

export function ToolTip({
  children,
  titleComponent,
  visible = true,
  invertColor,
}: TooltipComponentsProps) {
  return (
    (visible && (
      <CustomizedToolTip
        invertColor={invertColor}
        followCursor
        title={
          <div className={styles.container}>
            {typeof titleComponent == 'string' ? (
              <TransTextWithDefault text={titleComponent as string} />
            ) : (
              titleComponent
            )}
          </div>
        }
      >
        {children}
      </CustomizedToolTip>
    )) ||
    children
  );
}
