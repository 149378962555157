import { applyMiddleware, legacy_createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';
import rootSaga from './sagas';
import { createWrapper } from 'next-redux-wrapper';
import { ThemeType } from '@/enums/themeType';
import { Setting } from '@starsoft/common/models';

export type RootState = ReturnType<typeof rootReducer>;

export function makeStore() {
  let initialState: Partial<RootState> = {
    theme: { setting: {} as Setting, type: ThemeType.dark },
    gameTags: { gameTags: [] },
  };

  if (typeof window !== 'undefined') {
    if (window.initialState) {
      initialState.theme.setting = JSON.parse(
        JSON.stringify(window.initialState),
      );
    }

    if (window.gameTags) {
      initialState.gameTags.gameTags = JSON.parse(
        JSON.stringify(window.gameTags),
      );
    }
  }

  const sagaMiddleware = createSagaMiddleware();
  const store = legacy_createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
export type RootStore = ReturnType<typeof makeStore>;
export let store: RootStore | undefined = makeStore();

export function getStore() {
  if (!store) {
    store = makeStore();
  }

  return store;
}

export const wrapper = createWrapper<Store<RootState>>(getStore, {
  debug: process.env.NODE_ENV == 'development',
});
