import { Nullable } from '@starsoft/common/interfaces';
import {
  AuthActions,
  AuthRequestSendSmsPayload,
  PasswordRecoverHandleInputProps,
  PasswordRecoverSelectProps,
  AuthSendEmailPayload,
  AuthSetCurrentCoinSuccessPayload,
  SetRecoverEmailPayload,
} from './props';
import { Coin } from '@/models/coin';

export function requestSendSms(): AuthRequestSendSmsPayload {
  return {
    type: AuthActions.SendSms,
  };
}

export function requestSendEmail(): AuthSendEmailPayload {
  return {
    type: AuthActions.SendEmail,
  };
}

export function setRecoveryOption(option: string): PasswordRecoverSelectProps {
  return {
    type: AuthActions.SetRecoverOption,
    payload: {
      option,
    },
  };
}

export function setRecoverValue(
  optionValue: string,
): PasswordRecoverHandleInputProps {
  return {
    type: AuthActions.SetRecoverValue,
    payload: {
      optionValue,
    },
  };
}

export function setAuthCurrentCoin(
  coin: Coin,
): AuthSetCurrentCoinSuccessPayload {
  return {
    type: AuthActions.SetCurrentCoin,
    payload: {
      coin,
    },
  };
}

export function setRecoverEmail(
  payload: Nullable<string>,
): SetRecoverEmailPayload {
  return {
    type: AuthActions.SetRecoverEmail,
    payload,
  };
}
