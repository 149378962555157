import { CookiesKey } from '@starsoft/common/enums';
import axios, { AxiosInstance } from 'axios';
import { getCookie, hasCookie } from 'cookies-next';
import Router from 'next/router';
import { AuthService } from '../AuthService';

export class APIClient {
  public instance: AxiosInstance;

  constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL: baseURL + '/v1/',
      withCredentials: true,
      timeout: 10000,
    });

    this.setupInterceptors();
  }

  private setupInterceptors() {
    this.instance.interceptors.request.use(request => {
      if (!request.headers) return request;

      const langCookie = getCookie(CookiesKey.Lang);
      if (langCookie) {
        request.headers['x-lang'] = langCookie.slice(0, 2);
      }

      if (hasCookie(CookiesKey.Token)) {
        request.headers['authorization'] = getCookie(CookiesKey.Token);
      }

      if (hasCookie(CookiesKey.RefreshToken)) {
        request.headers['x-refresh-token'] = getCookie(CookiesKey.RefreshToken);
      }

      return request;
    });

    this.instance.interceptors.response.use(
      response => {
        AuthService.refreshAuthorization();

        if (response?.data?.accessToken) {
          AuthService.setToken(response.data.accessToken);
          this.instance.defaults.headers['authorization'] =
            response.data.accessToken;
        }

        if (response?.data?.refreshToken) {
          AuthService.setRefreshToken(response.data.refreshToken);
          this.instance.defaults.headers['x-refresh-token'] =
            response.data.refreshToken;
        }

        return response;
      },
      error => {
        const method: string = error.response?.config?.method ?? '';

        if (
          error?.response?.data?.statusCode === 401 &&
          method.toUpperCase() === 'GET'
        ) {
          AuthService.logOut();
          Router.prefetch('/');
          Router.push('/');
        }

        return Promise.reject(error);
      },
    );
  }
}
