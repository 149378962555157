import { GameTag } from '@/models/game/tag';

export enum GameTagsActions {
  HandleChangeGameTag = 'casino/handle-change-game-tag',
}

export interface GameTagsProps {
  gameTags: GameTag[];
}

export interface GameTagsPayload<T extends GameTagsActions, V = null> {
  type: T;
  payload: V;
}

export type HandleChangeGameTag = GameTagsPayload<
  GameTagsActions.HandleChangeGameTag,
  GameTag
>;

export type GameTagsStatePayload = HandleChangeGameTag;
