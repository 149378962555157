import { AudioService } from '@/services/AudioService';
import { SlideAction, SlideProps, SlideStatePayload } from './props';

const initialState: SlideProps = {
  numberCarousel: [],
  chosenMultiplier: 2,
  prizeIndex: 0,
  resultMultiplier: null,
  round: null,
  closedRound: false,
  init: false,
  isSocketConnected: false,
};

export default function reducer(
  state: SlideProps = initialState,
  action: SlideStatePayload,
): SlideProps {
  switch (action.type) {
    case SlideAction.GetTargetMultiplier:
      return {
        ...state,
        chosenMultiplier: action.payload,
      };
    case SlideAction.SetRound: {
      if (!state.round || state?.round?.id != action.payload.id) {
        const filteredCarousel = state.numberCarousel
          .filter((_, index) => index >= state.prizeIndex - 4)
          .filter((_, index) => index < 10);

        const newCarroussel = action.payload?.slideRound?.items as number[];
        return {
          ...state,
          round: action.payload,
          init: false,
          closedRound: false,
          resultMultiplier: action.payload?.slideRound?.multiplier as number,
          numberCarousel: filteredCarousel.concat(newCarroussel, newCarroussel),
        };
      } else {
        const result = action.payload?.slideRound?.multiplier;

        const newCarousel = state.numberCarousel;

        if (result != null) {
          newCarousel.splice(45, 1, +result);
        }

        const prizeIndex =
          action.payload?.slideRound?.multiplier != null
            ? state.numberCarousel.findIndex(
                (item: number, index) =>
                  index > 30 &&
                  item === +(action.payload?.slideRound?.multiplier as number),
              )
            : state.prizeIndex;

        return {
          ...state,
          round: action.payload,
          resultMultiplier: result,
          numberCarousel: newCarousel,
          init: false,
          prizeIndex,
        };
      }
    }

    case SlideAction.HandleStoppedSlide:
      if (!AudioService.isGamesMuted()) {
        const getMultiplierSound = () => {
          if (state.resultMultiplier == null) {
            return '0x';
          }

          if (+state.resultMultiplier < 1.99) {
            return '0x';
          }

          if (+state.resultMultiplier < 4.99) {
            return '2x';
          }

          if (+state.resultMultiplier < 9.99) {
            return '5x';
          }

          if (+state.resultMultiplier < 99.99) {
            return '10x';
          }

          if (+state.resultMultiplier < 999.99) {
            return '100x';
          }

          return '1000x';
        };
        AudioService.slideStopped(getMultiplierSound());
      }
      return {
        ...state,
        closedRound: true,
      };

    case SlideAction.Reset:
      return {
        ...state,
        round: null,
        closedRound: false,
      };

    case SlideAction.SetSocketConnection: {
      return {
        ...state,
        isSocketConnected: action.payload,
      };
    }
    default:
      return state;
  }
}
