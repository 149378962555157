import { GenericError } from '@/models/generic-error';
import { ErrorService } from '@/services/ErrorService';
import {
  QueryClient,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { UserBonusQueryKeys } from '../../queryKeys';
import { UserBonus } from '@/models/userBonus';
import { fetchToggleUserBonusActive } from './service';
import { useNotificationService } from '@/hooks/notifications/useNotificationService';

export function useToggleActiveUserBonus() {
  const queryClient: QueryClient = useQueryClient();
  const { NotificationsService } = useNotificationService();

  function onSuccess(userBonus: UserBonus) {
    queryClient.invalidateQueries({ queryKey: [UserBonusQueryKeys.Primary] });
    queryClient.invalidateQueries({ queryKey: [UserBonusQueryKeys.ById] });
    NotificationsService.success({
      title: 'activate_bonus_title',
      message: userBonus?.active
        ? 'activate_bonus_description'
        : 'inactive_bonus_description',
    });
  }

  function onError(e: AxiosError<GenericError>) {
    ErrorService.onError(e);
  }

  const { mutate, isPending } = useMutation({
    mutationFn: fetchToggleUserBonusActive,
    onSuccess,
    onError,
  });

  return {
    toggleUserBonusActive: mutate,
    loadingToggleUserBonusActive: isPending,
  };
}
