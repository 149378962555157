import { GameTagsStatePayload, GameTagsActions, GameTagsProps } from './props';

const initialState: GameTagsProps = {
  gameTags: [],
};

export default function gameTagsReducer(
  state: GameTagsProps = initialState,
  action: GameTagsStatePayload,
): GameTagsProps {
  switch (action.type) {
    case GameTagsActions.HandleChangeGameTag:
      return {
        ...state,
        gameTags: [action.payload],
      };

    default:
      return state;
  }
}
