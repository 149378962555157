import { TransText } from '@starsoft/common/interfaces/trans-text';
import { ComponentType } from 'react';
import { TransWithDefault } from '../TransWithDefault';
import { useSettingsStore } from '@starsoft/common/hooks/useSettingsStore';
import { CommonSettings } from '@starsoft/common/interfaces';

export function TransTextWithDefault({ components, ...props }: TransText) {
  const { state } = useSettingsStore();
  const settings: CommonSettings = state.settings;

  const TransText: ComponentType<TransText> =
    settings.translationComponents.TransText;

  if (!TransText) {
    return <TransWithDefault i18nKey="" {...props} />;
  }

  return (
    <TransText
      components={{
        strong: <strong />,
        br: <br />,
        p: <p />,
        div: <div />,
        span: <span />,
        ...components,
      }}
      {...props}
    />
  );
}
