import baseAPI from '@/api';
import { UserBonus } from '@/models/userBonus';
import { QueryFunctionContext } from '@tanstack/react-query';
import { UseUserBonusByIdQueryKeyProps } from './props';

export async function fetchUserBonusById({
  queryKey,
}: QueryFunctionContext<UseUserBonusByIdQueryKeyProps>) {
  const id = queryKey[1];
  const response = await baseAPI.get<UserBonus>(`/user-bonus/${id}`);
  return response.data;
}
