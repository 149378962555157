import { useSettingsStore } from '@starsoft/common/hooks/useSettingsStore';
import { CommonSettings } from '@starsoft/common/interfaces';
import { Trans as ITrans } from '@starsoft/common/interfaces/trans';
import { ComponentType } from 'react';

export function TransWithDefault({ components, ...props }: ITrans) {
  const { state } = useSettingsStore();
  const settings: CommonSettings = state.settings;
  const Trans: ComponentType<ITrans> = settings.translationComponents.Trans;

  return (
    <Trans
      components={{
        strong: <strong />,
        br: <br />,
        p: <p />,
        ul: <ul />,
        li: <li />,
        ...components,
      }}
      {...props}
    />
  );
}
