import { useWalletsMe } from '@/api/wallets';
import { ModalsKey } from '@/enums/modalsKey';
import { useCallback } from 'react';
import { useModalNavigation } from '../modals/useModalNavigation';
import { useSearchParams } from '@starsoft/common/hooks';
import { WithdrawStep } from '@/enums/withdrawStep';
import { CoinType } from '@starsoft/common/models';
import { CoinID } from '@starsoft/common/enums';

export default function useTransactionsNavigation() {
  const { wallet } = useWalletsMe();
  const currentAccount = wallet?.currentAccount;

  const { navigateToModal } = useModalNavigation();
  const params = useSearchParams();

  const getModalSearchParams = useCallback(
    (coinType?: CoinType, coinId?: CoinID) => {
      params.set('coin-type', coinType ?? currentAccount?.coin?.type);
      params.set(
        'coin-id',
        coinId ? String(coinId) : String(currentAccount?.coin?.id),
      );
      params.set('step', WithdrawStep.Form);

      return `?${params.toString()}`;
    },
    [params, currentAccount],
  );

  const navigateToTransactionModal = useCallback(
    (modal: ModalsKey.Deposit | ModalsKey.Withdraw) => {
      const searchParams = getModalSearchParams();
      navigateToModal(modal, searchParams);
    },
    [getModalSearchParams, navigateToModal],
  );

  return {
    navigateToTransactionModal,
    getModalSearchParams,
  };
}
