import { useLottiePlayer } from '@starsoft/common/hooks';
import { EmptySearchProps } from './props';
import styles from './styles.module.scss';
import { TransWithDefault } from '@starsoft/common/components';

export default function EmptySearch({ search }: EmptySearchProps) {
  const { lottieAnimationRef } = useLottiePlayer({
    path: '/empty-search.json',
  });

  return (
    <div className={styles.container}>
      <div className={styles.container__lottie} ref={lottieAnimationRef} />
      <p className={styles.container__text}>
        <TransWithDefault
          i18nKey={
            search ? 'support:not_found_description' : 'common:no_result'
          }
          values={{
            search: search,
          }}
        />
      </p>
    </div>
  );
}
