import { SettingLayoutComponentId } from '@starsoft/common/models';
import { RootState } from '@/lib/store';
import { useSelector } from 'react-redux';
import { useIsMobile } from '@starsoft/common/hooks';
import { useComponentVariant } from '../useComponentVariant';
import { ComponentTypeId } from '@starsoft/common/models';

export default function useLayoutSettings() {
  const isMobile = useIsMobile();
  const isCollapsed: boolean = useSelector(
    (state: RootState) => state.sideBar.collapsed,
  );
  const layoutVariant =
    useComponentVariant(ComponentTypeId.Layout) ??
    SettingLayoutComponentId.LayoutV1;
  const isV2LayoutVariant = layoutVariant == SettingLayoutComponentId.LayoutV2;

  return {
    layoutVariant,
    isV2LayoutVariant,
    isCollapsed: isMobile ? false : isCollapsed,
  };
}
