import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';
import { useLottiePlayer } from '@starsoft/common/hooks';
import { useDetectDepositBoosts } from '@/hooks/useDetectDepositBoosts';
import { Either } from '@starsoft/common/interfaces';
import { UserBonus } from '@/models/userBonus';

export default function DepositBoost() {
  const { t } = useTranslation('deposit');
  const { bonus } = useDetectDepositBoosts();
  const firstUserBonus: Either<UserBonus, undefined> = bonus?.[0];
  const { lottieAnimationRef } = useLottiePlayer({
    path: '/pink-gift.json',
    dependecyArray: [firstUserBonus],
  });

  if (!firstUserBonus) {
    return null;
  }

  return (
    <div className={styles.container}>
      <span className={styles.container__title}>
        {t('deposit_bonus_title')}
      </span>
      <p className={styles.container__description}>
        {t('deposit_bonus_description', {
          percentage: firstUserBonus?.bonus?.percentage,
        })}
      </p>

      <div className={styles.container__lottie} ref={lottieAnimationRef} />
    </div>
  );
}
