import { Device } from '@/enums/device';
import {
  CasinoActions,
  CasinoBasePayload,
  CasinoGameMode,
  HandleChangeProviderSearchPayload,
  HandleChangeSearchValuePayload,
  HandleProviderFilterPayload,
  RemoveSearchHistoryItemPayload,
  SetGameModePayload,
  SetLevelUp,
  SetHasSlotSession,
  SetModalSearchPayload,
  SetSearchHistory,
  SetUserDevicePayload,
  SetPlayCoinPayload,
} from './props';
import { Nullable } from '@starsoft/common/interfaces';
import { Rank } from '@/models/rank';
import { Coin } from '@starsoft/common/models';

export function handleChangeSearchValue(
  value: string,
): HandleChangeSearchValuePayload {
  return {
    type: CasinoActions.HandleInputChange,
    payload: value,
  };
}

export function handleChangeProviderSearchValue(
  value: string,
): HandleChangeProviderSearchPayload {
  return {
    type: CasinoActions.HandleInputProviderChange,
    payload: value,
  };
}

export function handleChangeViewport(): CasinoBasePayload<CasinoActions.HandleChangeViewport> {
  return {
    type: CasinoActions.HandleChangeViewport,
    payload: null,
  };
}

export function handleChangeGameMode(): CasinoBasePayload<CasinoActions.HandleChangeGameMode> {
  return {
    type: CasinoActions.HandleChangeGameMode,
    payload: null,
  };
}

export function handleProviderFilter(id: number): HandleProviderFilterPayload {
  return {
    type: CasinoActions.HandleProviderFilter,
    payload: id,
  };
}

export function handleResetAll(): CasinoBasePayload<CasinoActions.HandleResetAllParams> {
  return {
    type: CasinoActions.HandleResetAllParams,
    payload: null,
  };
}

export function setGameMode(gameMode: CasinoGameMode): SetGameModePayload {
  return {
    type: CasinoActions.SetGameMode,
    payload: gameMode,
  };
}

export function handleChangeGameModeToReal(): CasinoBasePayload<CasinoActions.HandleChangeModeToReal> {
  return {
    type: CasinoActions.HandleChangeModeToReal,
    payload: null,
  };
}

export function resetSearchProvider(): CasinoBasePayload<CasinoActions.HandleResetSearchProvider> {
  return {
    type: CasinoActions.HandleResetSearchProvider,
    payload: null,
  };
}

export function resetSearchGame(): CasinoBasePayload<CasinoActions.HandleResetSearchGame> {
  return {
    type: CasinoActions.HandleResetSearchGame,
    payload: null,
  };
}

export function toggleSearch(): CasinoBasePayload<CasinoActions.ToggleSearch> {
  return {
    type: CasinoActions.ToggleSearch,
    payload: null,
  };
}

export function setSearchHistory(value: string): SetSearchHistory {
  return {
    type: CasinoActions.SetSearchHistory,
    payload: value,
  };
}

export function resetSearcHistory(): CasinoBasePayload<CasinoActions.ResetSearchHistory> {
  return {
    type: CasinoActions.ResetSearchHistory,
    payload: null,
  };
}

export function removeSearchHistoryItem(
  index: number,
): RemoveSearchHistoryItemPayload {
  return {
    type: CasinoActions.RemoveSearchHistoryItem,
    payload: {
      index,
    },
  };
}

export function setModalSearch(search: string): SetModalSearchPayload {
  return {
    type: CasinoActions.SetModalSearch,
    payload: search,
  };
}

export function setUserDevice(device?: Device): SetUserDevicePayload {
  return {
    type: CasinoActions.SetUserDevice,
    payload: device,
  };
}

export function setHasSlotSession(hasSlotSession: boolean): SetHasSlotSession {
  return {
    type: CasinoActions.SetHasSlotSession,
    payload: hasSlotSession,
  };
}

export function setLevelUp(levelUp: Nullable<Rank>): SetLevelUp {
  return {
    type: CasinoActions.SetLevelUp,
    payload: levelUp,
  };
}

export function setCasinoPlayCoin(coin: Nullable<Coin>): SetPlayCoinPayload {
  return {
    type: CasinoActions.SetPlayCoin,
    payload: coin,
  };
}
