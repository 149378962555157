import { CookiesKey } from '@/enums/cookiesKey';
import { setGameMode } from '@/lib/store/casino/actions';
import { CasinoGameMode } from '@/lib/store/casino/props';
import { AuthService } from '@/services/AuthService';
import { useQueryClient } from '@tanstack/react-query';
import { deleteCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export function useLogoutCallback() {
  const queryClient = useQueryClient();
  const router = useRouter();
  const dispatch = useDispatch();

  const callback = useCallback(() => {
    AuthService.setAsUnauthorized();
    dispatch(setGameMode(CasinoGameMode.Demo));
    queryClient.removeQueries();
    router.prefetch('/');
    router.replace('/');
    deleteCookie(CookiesKey.HideBonusCardMobile);
    deleteCookie(CookiesKey.LastUserBonusId);
  }, [dispatch, queryClient, router]);

  return callback;
}
