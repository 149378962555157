export enum BonusStatusId {
  Coupon = 1,
  Spin = 2,
  Ticket = 3,
  Activated = 4,
  Issued = 5,
  Supplied = 6,
  Expired = 7,
  Invalidated = 8,
  Cancelled = 9,
  Claimed = 10,
}
