import { GenericError } from '@/models/generic-error';
import { ErrorService } from '@/services/ErrorService';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { UserRewardsQueryKeys } from '../../queryKeys';
import { fetchUserRewardsClaim } from './service';
import { BonusRewardsQueryKeys } from '@/api/bonus-rewards/queryKey';
import { BonusRewardTypesQueryKeys } from '@/api/bonus-reward-type/queryKeys';
import { useNotificationService } from '@/hooks/notifications/useNotificationService';

export function useUserRewardsClaim() {
  const queryClient = useQueryClient();
  const { NotificationsService } = useNotificationService();

  function onSuccess() {
    queryClient.invalidateQueries({ queryKey: [UserRewardsQueryKeys.Primary] });
    queryClient.invalidateQueries({ queryKey: [BonusRewardsQueryKeys.ByUser] });
    queryClient.invalidateQueries({
      queryKey: [UserRewardsQueryKeys.ByReward],
    });
    queryClient.invalidateQueries({
      queryKey: [BonusRewardTypesQueryKeys.Statistics],
    });
    NotificationsService.success({
      title: 'claim_reward_success_title',
      message: 'claim_reward_success_description',
    });
  }

  function onError(e: AxiosError<GenericError>) {
    ErrorService.onError(e);
  }

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: fetchUserRewardsClaim,
    onSuccess,
    onError,
  });

  return {
    claimUserReward: mutate,
    isLoading,
  };
}
