import { ComponentTypeId } from '@starsoft/common/models';
import { useResolvedTheme } from '../useResolvedTheme';
import { SettingLayoutComponentId } from '@starsoft/common/models';

export function useComponentVariant(
  typeId: ComponentTypeId,
): SettingLayoutComponentId {
  const { components } = useResolvedTheme();
  const componentVariant: SettingLayoutComponentId = components.get(typeId);

  return componentVariant;
}
