import { PaginatedResponse } from '../../models';

export function useInfiniteQueryCallbacks<T extends PaginatedResponse<T>>() {
  function getNextPageParam(lastPage: T) {
    if (lastPage?.metadata?.hasNextPage) {
      return lastPage.metadata.page + 1;
    }
    return null;
  }

  function getPreviousPageParam(firstPage: T) {
    if (firstPage?.metadata?.hasPreviousPage) {
      return firstPage.metadata.page - 1;
    }

    return null;
  }

  return {
    getNextPageParam,
    getPreviousPageParam,
  };
}
