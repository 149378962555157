export enum GameTagFilter {
  Egypt = 'egypt',
  AncientCivilizations = 'ancient_civilizations',
  Food = 'food',
  LuxuryLife = 'luxury_life',
  Fantasy = 'fantasy',
  Sweets = 'sweets',
  Space = 'space',
  Retro = 'retro',
  Party = 'party',
  Africa = 'africa',
  Sport = 'sport',
  Military = 'military',
  StPatricksDay = 'st_patrick_day',
  Asia = 'asia',
  UnderwaterWorld = 'underwater_world',
  ChristmasAndNewYear = 'x_mas_and_new_year',
  Halloween = 'halloween',
  Western = 'western',
  Branded = 'branded',
  Horrors = 'horrors',
  BookOff = 'book_off',
  Fruits = 'fruits',
  Girls = 'girls',
  Pirates = 'pirates',
  Joker = 'joker',
  Easter = 'easter',
  ValentinesDay = 'st_valentines_day',
  Fortune = 'fortune',
  Money = 'money',
  Tigers = 'tigers',
  Dragons = 'dragons',
  Rabbits = 'rabbits',
  Mouses = 'mouses',
  Pigs = 'pigs',
  Apes = 'apes',
  Cows = 'cows',
  Dogs = 'dogs',
  Cats = 'cats',
  Chickens = 'chickens',
  Horses = 'horses',
  Sheeps = 'sheeps',
  Wolfs = 'wolfs',
  Vampires = 'vampires',
  Werewolfs = 'werewolfs',
  Mermaids = 'mermaids',
  Sea = 'sea',
  Farm = 'farm',
  Animals = 'animals',
  Carnival = 'carnival',
  Recommended = 'recommended',
  Exclusives = 'exclusives',
  Freespins = 'freespins',
  NewReleases = 'new-releases',
  EnhancedRTP = 'enhanced-rtp',
  Jackpot = 'jackpot',
  Mobile = 'mobile',
  Desktop = 'desktop',
  LiveGame = 'live-game',
  LiveCasino = 'live-casino',
  Slots = 'slots',
  Scratch = 'scratch',
  Roulette = 'roulette',
  Card = 'card',
  Casual = 'casual',
  Lottery = 'lottery',
  Poker = 'poker',
  Baccarat = 'baccarat',
  Blackjack = 'blackjack',
  Dice = 'dice',
  Crash = 'crash',
  Fishing = 'fishing',
  Mines = 'mines',
  VideoPoker = 'video_poker',
  VirtualSports = 'virtual_sports',
  LowVolatility = 'low-volatility',
  LowMediumVolatility = 'low-medium-volatility',
  MediumLowVolatility = 'medium-low-volatility',
  MediumVolatility = 'medium-volatility',
  MediumHighVolatility = 'medium-high-volatility',
  HighVolatility = 'high-volatility',
  VeryHighVolatility = 'very-high-volatility',
  //aggregator after this
  Originals = 'ice-games',
  PocketGamesSoft = 'pgsoft',
  Spribe = 'spribe',
  PragmaticPlay = 'pragmaticexternal',
  Evolution = 'evolution',
  Playtech = 'playtech',
  OneXTwoGaming = '1x2gaming',
  OneSpinForWin = '1spin4win',
  ThreeOaks = '3oaks',
  FiveMenGaming = '5men',
  AbsoluteLiveGaming = 'alg',
  Amatic = 'amatic',
  Amigo = 'amigo',
  Apparat = 'apparat',
  Authentic = 'authentic',
  Avatarux = 'avatarux',
  BarbaraBang = 'barbarabang',
  Belatra = 'belatra',
  BETERLive = 'beterlive',
  BetSoft = 'bsg',
  Betsolutions = 'betsolutions',
  BFGames = 'bfgames',
  BlueprintGaming = 'blueprint',
  BoomingGames = 'booming',
  Caleta = 'caleta',
  Clawbuster = 'clawbuster',
  Copacabana = 'copacabana',
  CTInteractive = 'technology',
  EGT = 'egt',
  ElkStudios = 'elk',
  Endorphina = 'endorphina',
  HacksawGaming = 'hacksaw',
  Ezugi = 'ezugi',
  NolimitCity = 'nolimit',
  Evoplay = 'evoplay',
  Expanse = 'expanse',
  FantasmaGames = 'fantasma',
  Fazi = 'fazi',
  GameArt = 'gameart',
  GameBeat = 'gamebeat',
  GamingOne = 'gaming1',
  GamingCorps = 'gamingcorps',
  Gamzix = 'gamzix',
  Habanero = 'habanero',
  HighFiveGames = 'highfive',
  HolleGames = 'hollegames',
  OneTouch = 'onetouch',
  Igrosoft = 'igrosoft',
  IGTech = 'igtech',
  InOut = 'inout',
  Isoftbet = 'isoftbet',
  JadeRabbitStudio = 'jaderabbit',
  KAGaming = 'kagaming',
  KalambaGames = 'kalamba',
  LiveSolutions = 'livesolutions',
  Lucky = 'lucky',
  LuckyStreak = 'luckystreak',
  MancalaGaming = 'mancala',
  Mascot = 'mascot',
  MGAGames = 'mgagames',
  MrSlotty = 'mrslotty',
  Mplay = 'mplay',
  Netgame = 'netgame',
  Novomatic = 'novomatic',
  NucleusGaming = 'nucleus',
  Octoplay = 'octoplay',
  Onlyplay = 'onlyplay',
  OryxGaming = 'oryx',
  Pateplay = 'pateplay',
  Platipus = 'platipus',
  PlayAndGo = 'playngo',
  Popiplay = 'popiplay',
  PushGaming = 'pushgaming',
  Quickfire = 'quickfire',
  Quickspin = 'quickspin',
  RawIGaming = 'rawgaming',
  ReadyplayGaming = 'readyplay',
  Reevo = 'reevo',
  RelaxGaming = 'relax',
  RubyPlay = 'rubyplay',
  ScatterKings = 'scatterkings',
  SkywindGroup = 'skywind',
  Slotmill = 'slotmill',
  SmartsoftGaming = 'smartsoft',
  SpadeGaming = 'spadegaming',
  SpinmaticEntertainment = 'spinmatic',
  Spinomenal = 'spinomenal',
  Spinomenl = 'spnmnl',
  Spinoro = 'spinoro',
  Spinza = 'spinza',
  Swintt = 'swintt',
  Swntt = 'swntt',
  TaDaGaming = 'tadagaming',
  Thunderkick = 'thunderkick',
  TomHornGaming = 'tomhornnative',
  TornadoGames = 'tornadogames',
  TripleCherry = 'triplecherry',
  TrueLab = 'truelab',
  TurboGames = 'turbogames',
  VibraGaming = 'vibragaming',
  Wazdan = 'wazdan',
  Winfinity = 'winfinity',
  WizardGames = 'wizard',
  Yggdrasil = 'yggdrasil',
  ZillionGames = 'zillion',
  Atmosfera = 'atmosphera',
  Softswiss = 'softswiss',
  StarSoft = 'starsoft',
  Groove = 'groove',
  Infin = 'infin',
  Redgenn = 'redgenn',
  EveryMatrix = 'everymatrix',
}
