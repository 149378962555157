import useTranslation from 'next-translate/useTranslation';
import NextLink from 'next/link';
import { CoreLinkProps } from './props';
import { useUserAccount } from '@/api/user/queries';
import { ModalsKey } from '@/enums/modalsKey';
import { useRouter } from 'next/router';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { usePathname } from '@/hooks/usePathname';

export default function Link({
  as,
  children,
  href,
  replace,
  scroll,
  shallow,
  modal,
  privateRoute = false,
  ...rest
}: CoreLinkProps) {
  const { lang } = useTranslation('common');
  const { getModalLink } = useModalNavigation();
  const { isAuthorized } = useUserAccount();
  const { prefetch } = useRouter();
  const pathname: string = usePathname();

  function getHref() {
    if (privateRoute && !isAuthorized) {
      return `${pathname}#${ModalsKey.Login}`;
    }

    if (modal) {
      return getModalLink(modal, href);
    }

    return `${String(href).startsWith('/') ? href : `/${href}`}`;
  }

  function onMouseEnter() {
    prefetch(getHref());
  }

  return (
    //@ts-ignore
    <NextLink
      {...rest}
      as={as}
      href={getHref()}
      lang={lang}
      passHref
      replace={replace}
      scroll={!!modal ? false : scroll}
      aria-disabled={rest?.disabled}
      shallow={!!modal ? true : shallow}
      aria-label={`Link to ${href}`}
      onMouseEnter={onMouseEnter}
      onTouchStart={onMouseEnter}
    >
      {children}
    </NextLink>
  );
}
