import { NotificationGroupId } from '@starsoft/common/enums';
import {
  AddNotificationPayload,
  NotificationsActions,
  RemoveNotificationPayload,
  SetSocketConnectionPayload,
  SetTabPayload,
} from './props';
import { Notification } from '@starsoft/common/models';

export function setNotificationModalTab(
  tab: NotificationGroupId,
): SetTabPayload {
  return {
    type: NotificationsActions.SetTab,
    payload: tab,
  };
}

export function addNotificationToasty<T>(
  notification: Notification<T>,
): AddNotificationPayload<T> {
  return {
    type: NotificationsActions.AddNotification,
    payload: notification,
  };
}

export function removeNotificationToasty(
  id: number,
): RemoveNotificationPayload {
  return {
    type: NotificationsActions.RemoveNotification,
    payload: id,
  };
}

export function setNotificationsSocketConnection(
  connected: boolean,
): SetSocketConnectionPayload {
  return {
    type: NotificationsActions.SetSocketConnection,
    payload: connected,
  };
}
