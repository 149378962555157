import { Coin } from '@starsoft/common/models';
import { QueryFunctionContext } from '@tanstack/react-query';
import { UseCoinByIdQueryKey } from './props';
import { AxiosInstance, AxiosResponse } from 'axios';

export async function fetchCoinById(
  { queryKey }: QueryFunctionContext<UseCoinByIdQueryKey>,
  axiosInstance: AxiosInstance,
): Promise<Coin> {
  const id: number = queryKey[1];

  const response: AxiosResponse<Coin> = await axiosInstance.get<Coin>(
    `/coins/${id}`,
  );

  return response.data;
}
