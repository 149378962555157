import {
  WalletActions,
  WalletPayload,
  WalletSetConversionCoin,
  WalletSetIsUpdatingPayload,
} from './props';

export function collapseWallet(): WalletPayload<WalletActions.Collapse> {
  return {
    type: WalletActions.Collapse,
    payload: null,
  };
}

export function setHiddenValue(): WalletPayload<WalletActions.SetHiddenValue> {
  return {
    type: WalletActions.SetHiddenValue,
    payload: null,
  };
}

export function toggleConvertToFiat(): WalletPayload<WalletActions.ToggleConvertToCoin> {
  return {
    type: WalletActions.ToggleConvertToCoin,
    payload: null,
  };
}

export function setFiatConversionCoin(coinId: number): WalletSetConversionCoin {
  return {
    type: WalletActions.SetConversionCoin,
    payload: coinId,
  };
}

export function setIsUpdating(isUpdating: boolean): WalletSetIsUpdatingPayload {
  return {
    type: WalletActions.SetIsUpdating,
    payload: isUpdating,
  };
}
