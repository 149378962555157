import { useQuery } from '@tanstack/react-query';
import { CoinsQueryKey } from '../../queryKeys';
import { UseCoinByIdProps, UseCoinByIdQueryKey } from './props';
import { Coin, GenericError } from '@starsoft/common/models';
import { AxiosError, AxiosInstance } from 'axios';
import { fetchCoinById } from './service';
import { useAxiosInstance } from '@starsoft/common/hooks';

export function useCoinById({ id }: UseCoinByIdProps) {
  const queryKey: UseCoinByIdQueryKey = [CoinsQueryKey.ById, id as number];
  const axiosInstance: AxiosInstance = useAxiosInstance();

  const { data, isPending, error, refetch, fetchStatus } = useQuery<
    Coin,
    AxiosError<GenericError>,
    Coin,
    UseCoinByIdQueryKey
  >({
    queryKey,
    queryFn: queryContext => fetchCoinById(queryContext, axiosInstance),
    staleTime: 5 * 1000,
    enabled: !!id,
  });

  return {
    coin: data,
    isPending: isPending && fetchStatus !== 'idle',
    error,
    refetch,
  };
}
