import { GenericError } from '@/models/generic-error';
import { ErrorService } from '@/services/ErrorService';
import {
  QueryClient,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { UserBonusQueryKeys } from '../../queryKeys';
import { fetchUserBonusClaimCheckpoint } from './service';

export function useUserBonusClaimCheckpoint() {
  const queryClient: QueryClient = useQueryClient();

  function onSuccess() {
    queryClient.invalidateQueries({ queryKey: [UserBonusQueryKeys.Primary] });
    queryClient.invalidateQueries({ queryKey: [UserBonusQueryKeys.ById] });
  }

  function onError(e: AxiosError<GenericError>) {
    ErrorService.onError(e);
  }

  const { mutate, isPending } = useMutation({
    mutationFn: fetchUserBonusClaimCheckpoint,
    onSuccess,
    onError,
  });

  return {
    claimBonus: mutate,
    loadingClaimBonus: isPending,
  };
}
