import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';
import { useUserAccount } from '@/api/user/queries';
import { useRouter } from 'next/router';
import useLayoutSettings from '@/hooks/theme/useLayoutSettings';
import { SettingLayoutComponentId } from '@starsoft/common/models';
import Color from 'color';
import { getComputedColor } from '@starsoft/common/utils';
import { ToolTip } from '@starsoft/common/components';

export default function ReferSection() {
  const { t } = useTranslation('common');
  const { isAuthorized } = useUserAccount();
  const { push } = useRouter();

  const { isCollapsed, layoutVariant } = useLayoutSettings();

  function handleNavigate() {
    push(isAuthorized ? '/affiliates' : '/affiliates/panel');
  }

  return (
    <ToolTip titleComponent={t('refer_and_win')} visible={isCollapsed}>
      <div
        className={`${styles.container} ${isCollapsed ? styles['container--collapsed'] : ''} ${layoutVariant === SettingLayoutComponentId.LayoutV3 ? styles['container--v3'] : ''}`}
        onClick={handleNavigate}
        style={{
          //@ts-ignore
          '--primary-alpha':
            layoutVariant == SettingLayoutComponentId.LayoutV3
              ? Color(getComputedColor('--primary-color')).alpha(0.1).toString()
              : undefined,
        }}
      >
        <div className={styles.container__ticket}>
          <div className={styles.container__ticket__icon}>
            <i className="fa-solid fa-gift fa-swap-opacity" />
          </div>

          <div className={styles.container__row}>
            <span className={styles.container__ticket__label}>
              {t('refer_and_win')}
            </span>
          </div>
        </div>
      </div>
    </ToolTip>
  );
}
