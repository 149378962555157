import { MutableRefObject, useMemo } from 'react';
import { TabsProps } from './props';
import styles from './styles.module.scss';
import Tab from './Tab';
import { useCustomCarousel, useIndicator } from '@starsoft/common/hooks';
import { SettingLayoutComponentId } from '../../models';

export function Tabs<T>({
  tabs,
  isTiny,
  setTab,
  isSmall,
  version,
  isColumn,
  disabled,
  renderTab,
  currentTab,
  invertColors,
  useMaxContent,
  i18nNamespace = 'common',
  id = `tab-${tabs.toString()}`,
}: TabsProps<T>) {
  const {
    containerRef,
    handleMouseDown,
    handleMouseLeave,
    handleMouseMove,
    handleMouseUp,
    isDragging,
  } = useCustomCarousel();
  const indicatorRef: MutableRefObject<HTMLDivElement> = useIndicator({
    tabs,
    currentTab,
    containerRef,
    isDisabled: version !== SettingLayoutComponentId.TabsV1,
  });

  const Tabs = useMemo(
    () =>
      tabs.map(carouselTab =>
        !!renderTab ? (
          renderTab({
            setTab,
            version,
            isSmall,
            currentTab,
            i18nNamespace,
            tab: carouselTab,
          })
        ) : (
          <Tab
            setTab={setTab}
            isTiny={isTiny}
            tab={carouselTab}
            isSmall={isSmall}
            version={version}
            disabled={disabled}
            currentTab={currentTab}
            invertColors={invertColors}
            i18nNamespace={i18nNamespace}
            useMaxContent={useMaxContent}
            key={`game-section-carousel-tab-${carouselTab.value}`}
          />
        ),
      ),
    [
      tabs,
      setTab,
      isTiny,
      version,
      isSmall,
      disabled,
      renderTab,
      currentTab,
      invertColors,
      i18nNamespace,
      useMaxContent,
    ],
  );

  return (
    <div
      className={`${styles.container} ${isTiny ? styles['container--tiny'] : ''} ${version === SettingLayoutComponentId.TabsV3 ? styles['container--useContainer'] : ''} ${isDragging ? styles['container--dragging'] : ''} ${useMaxContent ? styles['container--max-content'] : ''} ${invertColors && version === SettingLayoutComponentId.TabsV3 ? styles['container--invert-colors'] : ''} ${version === SettingLayoutComponentId.TabsV1 ? styles['container--with-indicator'] : ''} ${isColumn ? styles['container--column'] : ''}`}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      ref={containerRef}
    >
      {Tabs}

      {version === SettingLayoutComponentId.TabsV1 && (
        <div
          className={styles.container__indicator}
          id={id}
          ref={indicatorRef}
        />
      )}
    </div>
  );
}
