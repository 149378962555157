import { SettingLayoutComponentId } from '@starsoft/common/models';
import SidebarHeaderContentLogo from './Logo';
import SidebarHeaderContentTabs from './Tabs';
import useLayoutSettings from '@/hooks/theme/useLayoutSettings';

export default function SidebarHeaderContent() {
  const { layoutVariant } = useLayoutSettings();

  switch (layoutVariant) {
    case SettingLayoutComponentId.LayoutV1:
      return <SidebarHeaderContentLogo />;
    case SettingLayoutComponentId.LayoutV2:
      return <SidebarHeaderContentTabs />;
    default:
      return <SidebarHeaderContentLogo />;
  }
}
