export enum CoinID {
  USD = 1,
  EUR = 2,
  CAD = 3,
  BRL = 4,
  JPY = 5,
  ARS = 6,
  CNY = 7,
  INR = 8,
  IDR = 9,
  KRW = 10,
  CHF = 11,
  MXN = 12,
  DKK = 13,
  NZD = 14,
  PHP = 15,
  RUB = 16,
  PEN = 17,
  AUD = 18,
  PLN = 19,
  VND = 20,
  TRY = 21,
  COP = 22,
  CLP = 23,
  BTC = 24,
  ETH = 25,
  USDT = 26,
  FDUSD = 27,
  USDC = 28,
  BNB = 29,
  SHIB = 30,
  DOGE = 31,
  XRP = 32,
  POL = 33,
  TRX = 34,
  SOL = 35,
  AXS = 36,
  TON = 37,
}
