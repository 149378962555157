import {
  RollPointActions,
  RollPointStatePayload,
  RollPointStateProps,
} from './props';

const initialState: RollPointStateProps = {
  animate: false,
  result: null,
};

export default function rollPointReducer(
  state: RollPointStateProps = initialState,
  action: RollPointStatePayload,
): RollPointStateProps {
  switch (action.type) {
    case RollPointActions.SetIsAnimating:
      return {
        ...state,
        animate: action.payload,
      };
    case RollPointActions.SetResult:
      return {
        ...state,
        result: action.payload,
      };
    default:
      return state;
  }
}
