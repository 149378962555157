import { RootState } from '@/lib/store';
import { useCoinById } from '@starsoft/common/api';
import { Nullable } from '@starsoft/common/interfaces';
import { useSelector } from 'react-redux';

export function useConversionCoin() {
  const coinId: Nullable<number> = useSelector(
    (state: RootState) => state.wallet.conversionCoinId,
  );
  const convertToCoin: boolean = useSelector(
    (state: RootState) => state.wallet.convertToCoin,
  );
  const { coin, error, isPending, refetch } = useCoinById({
    id: coinId,
  });

  return {
    coin: convertToCoin ? coin : null,
    error,
    isPending,
    refetch,
  };
}
