import { setGameMode } from '@/lib/store/casino/actions';
import { CasinoGameMode } from '@/lib/store/casino/props';
import { CookiesKey } from '@/enums/cookiesKey';
import { getStore } from '@/lib/store';
import { queryClient } from '@/lib/providers/TanstackQueryProvider';
import { deleteCookie, getCookie, hasCookie, setCookie } from 'cookies-next';
import { LoginByEmailSuccessOutputDto } from '../dto/auth/auth-login-response.dto';
import baseAPI from '@/api';

export class AuthService {
  static isAuthorized(): boolean {
    return (
      hasCookie(CookiesKey.IsAuthorized) &&
      hasCookie(CookiesKey.Token) &&
      hasCookie(CookiesKey.RefreshToken)
    );
  }

  static setAsAuthorized(data?: LoginByEmailSuccessOutputDto): void {
    if (typeof window === 'undefined') {
      return;
    }

    setCookie(CookiesKey.IsAuthorized, true, {
      maxAge: Number(process.env.NEXT_PUBLIC_JWT_EXPIRE_SECONDS as string),
      secure: true,
    });
    if (data) {
      AuthService.setToken(data.accessToken);
      AuthService.setRefreshToken(data.refreshToken);
    }
  }

  static setAsUnauthorized(): void {
    deleteCookie(CookiesKey.IsAuthorized);
    deleteCookie(CookiesKey.Token);
    deleteCookie(CookiesKey.RefreshToken);
  }

  static setToken(token: string): void {
    if (typeof window === 'undefined') {
      return;
    }

    setCookie(CookiesKey.Token, token, {
      maxAge: Number(process.env.NEXT_PUBLIC_JWT_EXPIRE_SECONDS as string),
      secure: true,
    });
    baseAPI.defaults.headers['authorization'] = token;
  }
  static setRefreshToken(token: string): void {
    if (typeof window === 'undefined') {
      return;
    }

    setCookie(CookiesKey.RefreshToken, token, {
      maxAge: Number(process.env.NEXT_PUBLIC_JWT_EXPIRE_SECONDS as string),
      secure: true,
    });
    baseAPI.defaults.headers['x-refresh-token'] = token;
  }

  static setVoucher(voucher: string): void {
    if (typeof window === 'undefined') {
      return;
    }

    setCookie(CookiesKey.Voucher, voucher, {
      maxAge: Number(process.env.NEXT_PUBLIC_JWT_EXPIRE_SECONDS as string),
      secure: true,
    });
  }

  static removeVoucher() {
    if (typeof window === 'undefined') {
      return;
    }

    deleteCookie(CookiesKey.Voucher);
  }

  static hasVoucher(): boolean {
    if (typeof window === 'undefined') {
      return false;
    }

    return hasCookie(CookiesKey.Voucher);
  }

  static getVoucher(): string | null {
    if (typeof window === 'undefined') {
      return null;
    }

    return getCookie(CookiesKey.Voucher) as string;
  }

  static setBetbyToken(token: string): void {
    if (typeof window === 'undefined') {
      return;
    }

    setCookie(CookiesKey.BetbyToken, token, {
      maxAge: Number(process.env.NEXT_PUBLIC_JWT_EXPIRE_SECONDS as string),
      secure: true,
    });
  }

  static getBetbyToken(): string | null {
    if (typeof window === 'undefined') {
      return null;
    }

    return getCookie(CookiesKey.BetbyToken) as string;
  }

  static removeBetbyToken(): void {
    if (typeof window === 'undefined') {
      return;
    }

    deleteCookie(CookiesKey.BetbyToken);
  }

  static hasBetbyToken(): boolean {
    if (typeof window === 'undefined') {
      return false;
    }

    return hasCookie(CookiesKey.BetbyToken);
  }

  static refreshAuthorization() {
    if (!AuthService.isAuthorized()) {
      return;
    }

    AuthService.setAsAuthorized();

    if (hasCookie(CookiesKey.Token)) {
      AuthService.setToken(getCookie(CookiesKey.Token) as string);
    }

    if (hasCookie(CookiesKey.RefreshToken)) {
      AuthService.setRefreshToken(getCookie(CookiesKey.RefreshToken) as string);
    }
  }

  static logOut(): void {
    AuthService.setAsUnauthorized();
    deleteCookie(CookiesKey.HideBonusCardMobile);
    deleteCookie(CookiesKey.LastUserBonusId);
    if (typeof window != 'undefined') {
      getStore().dispatch(setGameMode(CasinoGameMode.Demo));
      queryClient.removeQueries();
    }
  }
}
