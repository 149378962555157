import { Coin, CoinType, CurrencyOptions } from '@starsoft/common/models';
import { CreateMoneyPayload } from './props';
import { Either, Nullable } from '@starsoft/common/interfaces';
import BigNumber from 'bignumber.js';
import { usdFormatting } from '@starsoft/common/constants';

export class Money {
  private readonly units: number;
  private readonly locale: Either<string, undefined>;
  private readonly symbol: Either<string, undefined>;
  private readonly code: Either<string, undefined>;
  private readonly type: CoinType;
  private readonly scaleFactor: BigNumber;
  private readonly highScaleFactor: BigNumber;
  private _amount: number;

  constructor(createMoneyPayload: CreateMoneyPayload) {
    const coin: Coin | CurrencyOptions =
      createMoneyPayload?.coin ?? usdFormatting;
    this.code = coin.code;
    this.symbol = coin.symbol;
    this.locale = coin.locale;
    this.type = coin.type ?? CoinType.Fiat;
    this.units = coin.decimals;

    this.scaleFactor = new BigNumber(10).pow(this.units);
    this.highScaleFactor = new BigNumber(10).pow(this.units * 2);

    this._amount = this.fromSubunits(
      this.toSubunits(createMoneyPayload.amount ?? 0),
    );
  }

  get amount(): number {
    return this._amount;
  }

  set amount(value: number) {
    this._amount = this.fromSubunits(this.toSubunits(value));
  }

  get amountInSmallestUnit(): bigint {
    return this.toSubunits(this._amount);
  }

  get maskedAmount(): Nullable<string> {
    if (!this?.locale) {
      return null;
    }

    if (this.type === CoinType.Fiat) {
      return new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.code,
        currencyDisplay: 'symbol',
        minimumFractionDigits: this.units,
        maximumFractionDigits: this.units,
      })
        .format(this._amount)
        .replace(/\s/g, '');
    }

    return (
      new Intl.NumberFormat(this.locale, {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: this.units,
      }).format(this._amount) + ` ${this.symbol}`
    );
  }

  get formattedAmount(): Nullable<string> {
    if (!this?.locale) {
      return null;
    }

    const fixedAmount = this._amount.toFixed(this.units);
    if (this.type === CoinType.Fiat) {
      return new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.code,
        currencyDisplay: 'code',
        minimumFractionDigits: this.units,
        maximumFractionDigits: this.units,
      })
        .format(Number(fixedAmount))
        .replace(this.code ?? '', '')
        .replace(/\s/g, '');
    }

    return new Intl.NumberFormat(this.locale, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: this.units,
    }).format(Number(fixedAmount));
  }

  public add(value: number): void {
    const newAmountInSubunits: BigNumber = new BigNumber(
      this.amountInSmallestUnit.toString(),
    )
      .plus(this.toSubunits(value).toString())
      .integerValue(BigNumber.ROUND_FLOOR);

    this._amount = this.fromSubunits(BigInt(newAmountInSubunits.toString()));
  }

  public sub(value: number): void {
    const newAmountInSubunits: BigNumber = new BigNumber(
      this.amountInSmallestUnit.toString(),
    )
      .minus(this.toSubunits(value).toString())
      .integerValue(BigNumber.ROUND_FLOOR);

    this._amount = this.fromSubunits(BigInt(newAmountInSubunits.toString()));
  }

  public multiply(value: number, multiplier: number): number {
    const scaledMultiplier: BigNumber = new BigNumber(multiplier).multipliedBy(
      this.highScaleFactor,
    );
    const amountInSubUnits: BigNumber = new BigNumber(
      this.toSubunits(value).toString(),
    );

    const resultInSubunits: BigNumber = amountInSubUnits
      .multipliedBy(scaledMultiplier)
      .dividedBy(this.highScaleFactor)
      .integerValue(BigNumber.ROUND_FLOOR);

    return this.fromSubunits(BigInt(resultInSubunits.toString()));
  }

  public divide(value: number, divisor: number): number {
    const scaledDivisor: BigNumber = new BigNumber(divisor).multipliedBy(
      this.scaleFactor,
    );
    const amountInSubUnits: BigNumber = new BigNumber(
      this.toSubunits(value).toString(),
    );

    const resultInSubunits: BigNumber = amountInSubUnits
      .multipliedBy(this.scaleFactor)
      .dividedBy(scaledDivisor)
      .integerValue(BigNumber.ROUND_FLOOR);

    return this.fromSubunits(BigInt(resultInSubunits.toString()));
  }

  public toSubunits(value: number): bigint {
    const subunits: BigNumber = new BigNumber(value ?? 0)
      .multipliedBy(this.scaleFactor)
      .integerValue(BigNumber.ROUND_FLOOR);

    return BigInt(subunits.toString());
  }

  public fromSubunits(value: bigint | number | string): number {
    const amount: BigNumber = new BigNumber(value.toString())
      .dividedBy(this.scaleFactor)
      .decimalPlaces(this.units, BigNumber.ROUND_FLOOR);
    return amount.toNumber();
  }
}
