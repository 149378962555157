import { Nullable } from '@starsoft/common/interfaces';

export type UserBonusStateProps = {
  hideInactive: boolean;
};

export enum UserBonusActions {
  ToggleInactive = 'user-bonus/toggle-inactive',
}

export interface UserBonusBasePayload<T extends UserBonusActions, V = null> {
  type: T;
  payload: V;
}
export type ToggleUserBonusHideInactivesPayload = UserBonusBasePayload<
  UserBonusActions.ToggleInactive,
  Nullable<boolean>
>;

export type UserBonusStatePayload = ToggleUserBonusHideInactivesPayload;
