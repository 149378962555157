import { IAddBetNotification } from './props';
import games from '@/constants/games';

import { generateRandomId } from '@/utils/generateRandomId';
import { Notification } from '@/models/notification/notification';
import { NotificationGroupId } from '@/enums/notificationGroupId';
import { Bet } from '@/models/bet';
import { Game } from '@/models/game';
import { NotificationsService as CommonNotificationsService } from '@starsoft/common/services';
import { Notification as CommonNotification } from '@starsoft/common/models';
import { NotificationTypeId } from '@starsoft/common/enums';

export const NOTIFICATION_DURATION: number = 10000;
export class CasinoNotificationsService<
  T,
> extends CommonNotificationsService<T> {
  addBetNotification({ gameId, ...bet }: IAddBetNotification) {
    const id: number = generateRandomId();
    const game = games.get(gameId);

    const betWithGame: Bet = {
      ...bet,
      game: game as unknown as Game,
    };
    const notification: Notification = {
      id,
      pinned: false,
      type: {
        //@ts-ignore
        id: NotificationTypeId.GameBet,
        group: {
          id: NotificationGroupId.Inbox,
          name: 'Inbox',
        },
        name: 'GameBet',
      },
      extra: betWithGame,
      createdAt: `${new Date()}`,
    };

    this.addNotification(notification as unknown as CommonNotification<T>);
  }
}
