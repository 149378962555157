import { memo, PropsWithChildren } from 'react';
import { SidebarItemSectionProps } from './props';
import styles from './styles.module.scss';
import { SettingLayoutComponentId } from '@starsoft/common/models';
import useLayoutSettings from '@/hooks/theme/useLayoutSettings';
import { getComputedColor } from '@starsoft/common/utils';
import Color from 'color';
import { ToolTip } from '@starsoft/common/components';

function SidebarItemSection({
  icon,
  label,
  onClick,
  isOpen,
  shouldShowChevron,
  color,
  isHighligh,
  children,
}: PropsWithChildren<SidebarItemSectionProps>) {
  const { isCollapsed, layoutVariant } = useLayoutSettings();

  return (
    <ToolTip visible={isCollapsed} titleComponent={label}>
      <div
        className={`${styles.container} ${isOpen ? styles['container--open'] : ''} ${isHighligh ? styles['container--highlight'] : ''} ${isCollapsed ? styles['container--collapsed'] : ''} ${layoutVariant == SettingLayoutComponentId.LayoutV3 ? styles['container--v3'] : ''}`}
        onClick={onClick}
        style={{
          //@ts-ignore
          '--primary-alpha':
            layoutVariant == SettingLayoutComponentId.LayoutV3
              ? Color(getComputedColor('--primary-color')).alpha(0.1).toString()
              : undefined,
        }}
      >
        <div className={styles.container__row}>
          {icon && (
            <i
              className={`${styles.container__icon} ${icon}`}
              style={{ color }}
            />
          )}

          {children}

          {!isCollapsed ? label : null}
        </div>

        {shouldShowChevron && !isCollapsed && (
          <button
            className={`${styles.container__chevron} ${isOpen ? styles['container__chevron--open'] : ''}`}
          >
            <i className="fas fa-chevron-right" />
          </button>
        )}
      </div>
    </ToolTip>
  );
}

export default memo(SidebarItemSection);
