import { FieldPath, FieldValues } from 'react-hook-form';

export function get<T extends FieldValues, K extends FieldPath<T>>(
  object: T,
  path: K,
): T[K] {
  const keys = path.split('.') as (keyof T)[];
  let result: T | T[K] = object;

  for (const key of keys) {
    result = result?.[key];
  }

  return result as T[K];
}
