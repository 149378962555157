import { Bet } from '@/models/bet';

export enum LimboActions {
  EnterBetSuccess = 'limbo/enterBetSuccess',
  HandleAnimate = 'limbo/handleAnimate',
  HandleChangePredictMultiplier = 'limbo/handleChangePredictMultiplier',
  HandleReset = 'limbo/handleReset',
}

export interface LimboProps {
  bet: Bet | null;
  predictMultiplier: number | undefined;
  winChance: string | null;
  limboMultiplier: number;
  animate: boolean;
}

export interface LimboBasePayload {
  type: string;
}

export type HandleLimboSidePayload = LimboBasePayload;
export type HandleAnimateLimbo = LimboBasePayload;
export type AnimateLimbo = LimboBasePayload;

export interface LimboPredictMultiplierPayload extends LimboBasePayload {
  payload: {
    multiplier: number | undefined;
  };
}

export interface LimboEnterBetSuccessPayload extends LimboBasePayload {
  payload: {
    bet: Bet;
  };
}

export type LimboStatePayload = LimboBasePayload | LimboEnterBetSuccessPayload;
